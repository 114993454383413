// ConversationStoreUpdater.tsx - Without infinite loop

import React, { useEffect, useRef, useState, useCallback } from "react";
import { useConversationStore } from "@Ship/store/conversationsStore";
import {
  useMeQuery,
  useGetAllConversationsQuery,
  useGetConversationByIdLazyQuery,
  useWatchNewConversationMessageSubscription,
  useGetAllConversationsLazyQuery,
} from "src/generated/graphql";

const SUBSCRIPTION_RETRY_INTERVAL = 60000; // 1 minute
const MAX_RETRY_ATTEMPTS = 5;

// Function to merge new conversations data with existing conversations
// Define this outside the component to avoid recreation on each render
const mergeConversationsData = (
  newConversations: any[],
  existingConversations: any[]
) => {
  return newConversations.map((newConv) => {
    // Find matching existing conversation
    const existingConv = existingConversations.find(
      (conv) => conv.id === newConv.id
    );

    if (!existingConv) {
      // If conversation is new, just return it
      return newConv;
    }

    // Merge the conversation metadata (keep the new metadata)
    const mergedConv = { ...newConv };

    // For messages, we need to merge and deduplicate
    if (existingConv.messages && existingConv.messages.length > 0) {
      // Create a map of existing messages by ID for quick lookup
      const existingMsgMap = new Map();
      existingConv.messages.forEach((msg: any) => {
        if (msg && msg.id) {
          existingMsgMap.set(msg.id, msg);
        }
      });

      // Start with new messages
      let allMessages = [...(newConv.messages || [])];

      // Add existing messages that aren't in the new messages
      existingConv.messages.forEach((msg: any) => {
        if (msg && msg.id) {
          // Check if this message ID is already in the new messages
          const exists = allMessages.some(
            (newMsg: any) => newMsg.id === msg.id
          );
          if (!exists) {
            allMessages.push(msg);
          }
        }
      });

      // Sort messages by createdAt timestamp (newest first for consistency)
      allMessages.sort((a: any, b: any) => {
        const timeA = a?.createdAt ? parseInt(a.createdAt, 10) : 0;
        const timeB = b?.createdAt ? parseInt(b.createdAt, 10) : 0;
        return timeB - timeA; // Newest first
      });

      mergedConv.messages = allMessages;
    }

    return mergedConv;
  });
};

const ConversationStoreUpdater = () => {
  // const audioRef = useRef(new Audio("/message-notification.mp3"));
  const [_isSubscriptionActive, setIsSubscriptionActive] = useState(true);
  const retryTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const retryAttemptsRef = useRef(0);
  // Keep a local ref to avoid dependency cycles
  const conversationsRef = useRef<any[]>([]);

  const { data: meData } = useMeQuery();
  const userId = meData?.me?.id || "";

  const {
    setConversations,
    setDetailedConversation,
    setLoading,
    setFetchDetailedConversationCBF,
    setFetchAllConversationsCBF,
    setUnreadConversationsCount,
    calculateUnreadCount,
    conversations,
  } = useConversationStore();

  // Update our local ref whenever conversations change
  useEffect(() => {
    conversationsRef.current = conversations;
  }, [conversations]);

  // Update unread count whenever conversations or user ID changes
  useEffect(() => {
    if (!userId || !conversations.length) return;

    const unreadCount = calculateUnreadCount(conversations, userId);
    setUnreadConversationsCount(unreadCount);
  }, [
    conversations,
    userId,
    calculateUnreadCount,
    setUnreadConversationsCount,
  ]);

  const { data: conversationsData, loading: conversationsLoading } =
    useGetAllConversationsQuery({
      variables: {
        messageLimit: 1, // Limit to latest message per conversation for list view
      },
      fetchPolicy: "cache-and-network",
    });

  const scheduleSubscriptionRetry = () => {
    if (retryAttemptsRef.current < MAX_RETRY_ATTEMPTS) {
      retryTimeoutRef.current = setTimeout(
        () => {
          console.log(
            `Attempting to reconnect conversation subscription (attempt ${
              retryAttemptsRef.current + 1
            }/${MAX_RETRY_ATTEMPTS})`
          );
          retryAttemptsRef.current += 1;
          setIsSubscriptionActive(true);
        },
        SUBSCRIPTION_RETRY_INTERVAL * Math.pow(2, retryAttemptsRef.current)
      );
    } else {
      console.log("Max retry attempts reached for conversation subscription.");
    }
  };

  const clearRetryTimeout = () => {
    if (retryTimeoutRef.current) {
      clearTimeout(retryTimeoutRef.current);
      retryTimeoutRef.current = null;
    }
  };

  const handleSubscriptionSuccess = () => {
    setIsSubscriptionActive(true);
    clearRetryTimeout();
    retryAttemptsRef.current = 0;
    console.log("Conversation subscription successfully connected");
  };

  const handleSubscriptionError = (error: Error) => {
    console.error("Conversation subscription error:", error);
    setIsSubscriptionActive(false);
    scheduleSubscriptionRetry();
  };

  // Create lazy query for fetching conversation details
  const [getConversationById] = useGetConversationByIdLazyQuery();

  const [getAllConversations] = useGetAllConversationsLazyQuery({
    variables: {
      messageLimit: 1, // We'll merge with existing messages
    },
    fetchPolicy: "cache-and-network",
  });

  const fetchAllConversations = useCallback(async () => {
    console.log("fetchAllConversations called from callback");
    const result = await getAllConversations();

    if (result.data?.getAllConversations) {
      console.log("Refreshed conversations data via callback");

      // Merge the new conversations with existing ones to preserve message history
      // Use the ref instead of the state to avoid dependency cycles
      const mergedConversations = mergeConversationsData(
        result.data.getAllConversations,
        conversationsRef.current
      );

      setConversations(mergedConversations);

      // Calculate and set unread conversations count
      if (userId) {
        const unreadCount = calculateUnreadCount(mergedConversations, userId);
        setUnreadConversationsCount(unreadCount);
      }
    }

    return result;
  }, [
    getAllConversations,
    setConversations,
    setUnreadConversationsCount,
    calculateUnreadCount,
    userId,
    // conversations removed from dependencies to avoid infinite loop
  ]);

  // Define fetchDetailedConversation as a useCallback to prevent unnecessary re-renders
  const fetchDetailedConversation = useCallback(
    async (conversationId: string) => {
      try {
        console.log("Fetching detailed conversation:", conversationId);
        setLoading(true);

        const { data, error } = await getConversationById({
          variables: { conversationId },
          fetchPolicy: "network-only", // Force a network request to get fresh data
        });

        if (error) {
          console.error("Error fetching conversation details:", error);
          setLoading(false);
          return null;
        }

        if (data?.getConversationById) {
          // When we get a detailed conversation, we need to merge it with existing data
          const detailedConv = data.getConversationById;

          // Find the existing conversation to merge with - use the ref here
          const existingConv = conversationsRef.current.find(
            (conv) => conv.id === detailedConv.id
          );

          if (existingConv) {
            // Merge messages from existing conversation that aren't in the detailed view
            if (existingConv.messages && existingConv.messages.length > 0) {
              const detailedMsgIds = new Set(
                detailedConv.messages?.map((m: any) => m.id) || []
              );

              // Find messages in existing conversation that aren't in detailed view
              const additionalMessages = existingConv.messages.filter(
                (msg: any) => msg && msg.id && !detailedMsgIds.has(msg.id)
              );

              // Add these messages to the detailed conversation
              if (additionalMessages.length > 0) {
                detailedConv.messages = [
                  ...(detailedConv.messages || []),
                  ...additionalMessages,
                ];

                // Sort messages by createdAt timestamp
                detailedConv.messages.sort((a: any, b: any) => {
                  const timeA = a?.createdAt ? parseInt(a.createdAt, 10) : 0;
                  const timeB = b?.createdAt ? parseInt(b.createdAt, 10) : 0;
                  return timeB - timeA; // Newest first for consistency
                });
              }
            }
          }

          setDetailedConversation(detailedConv);
          setLoading(false);
          return detailedConv;
        }

        setLoading(false);
        return null;
      } catch (error) {
        console.error("Exception fetching conversation details:", error);
        setLoading(false);
        return null;
      }
    },
    [getConversationById, setDetailedConversation, setLoading]
    // conversations removed from dependencies to avoid infinite loop
  );

  // Set up subscription for new conversation messages
  useWatchNewConversationMessageSubscription({
    variables: {
      userId: userId ? parseInt(userId, 10) : 0,
    },
    skip: !userId,
    onData: (data) => {
      console.log("New conversation message received:", data);

      try {
        const newMessageDetails =
          data?.data?.data?.newConversationMessage?.newMessageDetails;

        if (newMessageDetails) {
          handleSubscriptionSuccess();

          // Fetch the updated conversation to update the store
          const conversationId = newMessageDetails.conversationId;
          if (conversationId) {
            fetchDetailedConversation(conversationId);

            // Also refresh all conversations to update unread counts
            fetchAllConversations();
          }
        }
      } catch (error) {
        console.error(
          "Error processing conversation subscription data:",
          error
        );
        handleSubscriptionError(error as Error);
      }
    },
    onError: handleSubscriptionError,
  });

  // Initial load of conversations
  useEffect(() => {
    if (conversationsData?.getAllConversations) {
      // Merge with existing conversations on initial load
      const mergedConversations = mergeConversationsData(
        conversationsData.getAllConversations,
        conversationsRef.current
      );

      setConversations(mergedConversations);

      // Calculate and set unread conversations count
      if (userId) {
        const unreadCount = calculateUnreadCount(mergedConversations, userId);
        setUnreadConversationsCount(unreadCount);
      }
    }
  }, [
    conversationsData,
    setConversations,
    setUnreadConversationsCount,
    calculateUnreadCount,
    userId,
    // conversations removed from dependencies to avoid infinite loop
  ]);

  // Set loading state
  useEffect(() => {
    setLoading(conversationsLoading);
  }, [conversationsLoading, setLoading]);

  // Set the fetch detailed conversation callback
  useEffect(() => {
    setFetchDetailedConversationCBF(fetchDetailedConversation);
    console.log("Setting fetchDetailedConversationCBF in store");
  }, [fetchDetailedConversation, setFetchDetailedConversationCBF]);

  // Set the fetch all conversations callback
  useEffect(() => {
    setFetchAllConversationsCBF(fetchAllConversations);
  }, [fetchAllConversations, setFetchAllConversationsCBF]);

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      clearRetryTimeout();
    };
  }, []);

  return <div className="hidden"></div>;
};

export default ConversationStoreUpdater;
