import "./../src/firebase/firebaseConfig";
import "react-toastify/dist/ReactToastify.css";
import React, { useEffect, useState } from "react";
import "@Ship/Styles/global.css";
import Head from "next/head";
import type { ReactElement, ReactNode } from "react";
import type { NextPage } from "next";
import type { AppProps, AppType } from "next/app";
import { QueryClientProvider, QueryClient } from "react-query";
import { ApolloProvider } from "@apollo/client";
import { useApollo } from "@Ship/Config/apollo/apolloClient";
import { type BeforeInstallPromptEvent } from "@Ship/Schema";
import AllOrdersSubscriptionManager from "@Ship/Components/AllOrdersSubscriptionManager/allOrdersSubscriptionManager";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { FaSpinner } from "react-icons/fa";
import AuthProvider from "./authProvider";
import ConversationStoreUpdater from "@Ship/Components/ConversationStoreUpdater";

export type NextPageWithLayout<P = object, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AppPropsWithLayout = AppProps<any> &
  AppType & {
    Component: NextPageWithLayout;
  };

// !! DANGER ZONE
// TODO
declare global {
  interface WindowEventMap {
    beforeinstallprompt: BeforeInstallPromptEvent;
  }

  interface Navigator {
    standalone?: boolean;
  }
}

if (typeof window !== "undefined" && process.env.NODE_ENV === "development") {
  const whyDidYouRender = require("@welldone-software/why-did-you-render");
  whyDidYouRender(React, {
    trackAllPureComponents: true,
    logOnDifferentValues: true,
  });
}

function MyApp({
  Component,
  pageProps: { session, ...pageProps },
}: AppPropsWithLayout) {
  const apolloClient = useApollo(pageProps);
  const getLayout = Component.getLayout ?? ((page) => page);
  const [authInitialized, setAuthInitialized] = useState(false);

  useEffect(() => {
    const auth = getAuth();

    // Listen for auth state changes
    const unsubscribe = onAuthStateChanged(
      auth,
      () => {
        setAuthInitialized(true);
      },
      (error) => {
        console.error("Auth state change error:", error);
        setAuthInitialized(true);
      }
    );

    return () => unsubscribe();
  }, []);

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retryDelay: (attemptIndex) => Math.min(1000 * 3 ** attemptIndex, 30000),
        refetchOnWindowFocus: false,
      },
    },
  });

  if (!authInitialized) {
    return (
      <div className="flex items-center justify-center min-h-screen min-w-full bg-white">
        <FaSpinner className="animate-spin text-5b5-softer-blue text-4xl" />
      </div>
    );
  }

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <ApolloProvider client={apolloClient}>
          <div className="text-gray-900 bg-white">
            {getLayout(
              <>
                <Head>
                  <title>Y Wait</title>
                  <meta
                    name="viewport"
                    content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, user-scalable=no, viewport-fit=cover"
                  />
                  <script src="https://www.google.com/recaptcha/api.js"></script>
                  <script
                    dangerouslySetInnerHTML={{
                      __html: `
                        (function(h,o,t,j,a,r){
                            h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                            h._hjSettings={hjid:5181184,hjsv:6};
                            a=o.getElementsByTagName('head')[0];
                            r=o.createElement('script');r.async=1;
                            r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                            a.appendChild(r);
                        })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
                  `,
                    }}
                  />
                </Head>
                <AuthProvider>
                  <Component {...pageProps} />
                  <ConversationStoreUpdater />
                </AuthProvider>
                <AllOrdersSubscriptionManager />
              </>
            )}
          </div>
        </ApolloProvider>
      </QueryClientProvider>
    </>
  );
}

export default MyApp;
