import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  Upload: any;
};

export type AdminMerchantCount = {
  __typename?: 'AdminMerchantCount';
  approved?: Maybe<MerchantCountWithStatus>;
  inactive?: Maybe<MerchantCountWithStatus>;
  pending?: Maybe<MerchantCountWithStatus>;
  total?: Maybe<MerchantCountWithStatus>;
};

export type AdminMerchants = {
  __typename?: 'AdminMerchants';
  merchants?: Maybe<Array<Maybe<Business>>>;
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
};

export type AdminPermission = {
  __typename?: 'AdminPermission';
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  permission?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

/**
 * AppSetting is an object that holds the important values that are relevant to the Y Wait App.
 *
 * Example:
 *
 *     {
 *       id:1,
 *       option:"defaultServiceFee",
 *       value:"0.05",
 *       previousValue:NULL,
 *       lastUpdatedBy:1,
 *       createdAt:2023-01-01 01:01:01,
 *       updatedAt:2023-01-01 01:01:01
 *     }
 */
export type AppSetting = {
  __typename?: 'AppSetting';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Int']>;
  /** LastUpdatedBy is the user ID (as integer) of the admin user that changed this specific app setting. */
  lastUpdatedBy?: Maybe<Scalars['Int']>;
  /** Option holds the name of the appSetting, e.g., 'defaultServiceFee' */
  option?: Maybe<Scalars['String']>;
  /** Previous value of the 'value' field. */
  previousValue?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** Value holds the value of the appSetting as a string, e.g., '0.05'. This should be parsed upon use. */
  value?: Maybe<Scalars['String']>;
};

export type AppSettingInputData = {
  /** The name of the appSetting. */
  option: Scalars['String'];
  /** The value of the appSetting taken as a string. */
  value: Scalars['String'];
};

export type BankAccount = {
  __typename?: 'BankAccount';
  accountName?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
  bankAddress?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
  bsb?: Maybe<Scalars['String']>;
};

export type BankAccountData = {
  accountName?: InputMaybe<Scalars['String']>;
  accountNumber?: InputMaybe<Scalars['String']>;
  bankAddress?: InputMaybe<Scalars['String']>;
  bankName?: InputMaybe<Scalars['String']>;
  bsb?: InputMaybe<Scalars['String']>;
};

export type BankAccountDetails = {
  accountName?: InputMaybe<Scalars['String']>;
  accountNumber?: InputMaybe<Scalars['String']>;
  bankAddress?: InputMaybe<Scalars['String']>;
  bankName?: InputMaybe<Scalars['String']>;
  bsb?: InputMaybe<Scalars['String']>;
};

export type BankAccountDetailsType = {
  accountName: Scalars['String'];
  accountNumber: Scalars['String'];
  bankAddress: Scalars['String'];
  bankName: Scalars['String'];
  bsb: Scalars['String'];
};

export type BillPaymentType = {
  __typename?: 'BillPaymentType';
  accountNumber?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  business?: Maybe<Business>;
  convenienceFee?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  paymentType?: Maybe<Scalars['String']>;
  receivedAmount?: Maybe<Scalars['Float']>;
  serviceFee?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['String']>;
  subTotal?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vat?: Maybe<Scalars['Float']>;
  vcan?: Maybe<Scalars['String']>;
};

export type BillPayments = {
  __typename?: 'BillPayments';
  page?: Maybe<Scalars['Int']>;
  pageCount?: Maybe<Scalars['Int']>;
  paymentData?: Maybe<Array<Maybe<BillPaymentType>>>;
  perPage?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
};

export type BillerReturnType = {
  __typename?: 'BillerReturnType';
  data?: Maybe<Array<Maybe<User>>>;
  itemCount?: Maybe<Scalars['Int']>;
  pageCount?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
};

export type BillsPaymentData = {
  accountNumber?: InputMaybe<Scalars['String']>;
  amount?: InputMaybe<Scalars['Float']>;
  billerId: Scalars['ID'];
};

export type Business = {
  __typename?: 'Business';
  abn?: Maybe<Scalars['String']>;
  banner?: Maybe<Scalars['String']>;
  businessId: Scalars['ID'];
  businessName?: Maybe<Scalars['String']>;
  businessSchedules?: Maybe<Array<Maybe<BusinessSchedules>>>;
  city?: Maybe<Scalars['String']>;
  closingTime?: Maybe<Scalars['DateTime']>;
  companyType?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  daysOpen?: Maybe<Scalars['String']>;
  formattedAddress?: Maybe<Scalars['String']>;
  isAcceptingOrders?: Maybe<Scalars['Boolean']>;
  isDisabled?: Maybe<Scalars['Boolean']>;
  isManualOpenCloseUpdate?: Maybe<Scalars['Boolean']>;
  isOpen?: Maybe<Scalars['Boolean']>;
  isOpenCalc?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  latitude?: Maybe<Scalars['Float']>;
  logo?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['Float']>;
  merchantType?: Maybe<Scalars['String']>;
  openingTime?: Maybe<Scalars['DateTime']>;
  products?: Maybe<Array<Maybe<Product>>>;
  qrCode?: Maybe<Scalars['String']>;
  redirectUrl?: Maybe<Scalars['String']>;
  signedS3UrlBanner?: Maybe<Scalars['String']>;
  signedS3UrlLogo?: Maybe<Scalars['String']>;
  storeDescription?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<Array<Maybe<User>>>;
  zipcode?: Maybe<Scalars['String']>;
};

export type BusinessBannerType = {
  __typename?: 'BusinessBannerType';
  banner?: Maybe<Scalars['String']>;
  signedS3UrlBanner?: Maybe<Scalars['String']>;
};

export type BusinessData = {
  businessName?: InputMaybe<Scalars['String']>;
  companyType?: InputMaybe<CompanyType>;
};

export type BusinessDetails = {
  bankAccountData?: InputMaybe<BankAccountData>;
  businessName?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  formattedAddress?: InputMaybe<Scalars['String']>;
  isOpen?: InputMaybe<Scalars['Boolean']>;
  latitude?: InputMaybe<Scalars['Float']>;
  longitude?: InputMaybe<Scalars['Float']>;
  storeDescription?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
  zipcode?: InputMaybe<Scalars['String']>;
};

/**
 * openingTime - datetime string in the format 'YYYY-MM-DD HH:mm:ss' in UTC timezone
 *
 * closingTIme - datetime string in the format 'YYYY-MM-DD HH:mm:ss' in UTC timezone
 */
export type BusinessHours = {
  /** closingTIme - datetime string in the format 'YYYY-MM-DD HH:mm:ss' in UTC timezone */
  closingTime: Scalars['String'];
  /** openingTime - datetime string in the format 'YYYY-MM-DD HH:mm:ss' in UTC timezone */
  openingTime: Scalars['String'];
};

export type BusinessHoursResponseType = {
  __typename?: 'BusinessHoursResponseType';
  businessName?: Maybe<Scalars['String']>;
  closingTime?: Maybe<Scalars['DateTime']>;
  daysOpen?: Maybe<Scalars['String']>;
  isOpen?: Maybe<Scalars['Boolean']>;
  isOpenCalc?: Maybe<Scalars['Boolean']>;
  openingTime?: Maybe<Scalars['DateTime']>;
};

export type BusinessLogoType = {
  __typename?: 'BusinessLogoType';
  logo?: Maybe<Scalars['String']>;
  signedS3UrlLogo?: Maybe<Scalars['String']>;
};

export type BusinessSchedule = {
  businessId?: InputMaybe<Scalars['String']>;
  /** closingTime - datetime string in the format 'YYYY-MM-DD HH:mm:ss' in UTC timezone */
  closingTime: Scalars['String'];
  day?: InputMaybe<Scalars['String']>;
  /** openingTime - datetime string in the format 'YYYY-MM-DD HH:mm:ss' in UTC timezone */
  openingTime: Scalars['String'];
  scheduleIsOpen?: InputMaybe<Scalars['Boolean']>;
};

export type BusinessSchedules = {
  __typename?: 'BusinessSchedules';
  businessScheduleId: Scalars['ID'];
  closingTime?: Maybe<Scalars['DateTime']>;
  day?: Maybe<Scalars['String']>;
  openingTime?: Maybe<Scalars['DateTime']>;
  scheduleIsOpen?: Maybe<Scalars['Boolean']>;
};

export type BusinessType = {
  __typename?: 'BusinessType';
  createdAt?: Maybe<Scalars['DateTime']>;
  icon?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  isDisabled?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['String']>;
};

export type BusinessTypeData = {
  value: Scalars['String'];
};

export type BusinessTypeResponseData = {
  __typename?: 'BusinessTypeResponseData';
  data?: Maybe<Array<Maybe<BusinessType>>>;
  itemCount?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  pagesCount?: Maybe<Scalars['Float']>;
  rows?: Maybe<Scalars['Int']>;
};

export type BuskingMerchantDetailsData = {
  bankAccountDetails?: InputMaybe<BankAccountDetails>;
  profile?: InputMaybe<MerchantProfileType>;
};

export type CardBillPaymentData = {
  amount?: InputMaybe<Scalars['Float']>;
  businessId: Scalars['ID'];
  card_country?: InputMaybe<Scalars['String']>;
  card_expiry?: InputMaybe<Scalars['String']>;
  card_holder?: InputMaybe<Scalars['String']>;
  card_number?: InputMaybe<Scalars['String']>;
  card_token: Scalars['String'];
  customer_ip: Scalars['String'];
};

export type CardBillPaymentReturnType = {
  __typename?: 'CardBillPaymentReturnType';
  data?: Maybe<FatZebraReturnType>;
};

export type CardPurchaseData = {
  card_country?: InputMaybe<Scalars['String']>;
  card_expiry?: InputMaybe<Scalars['String']>;
  card_holder?: InputMaybe<Scalars['String']>;
  card_number?: InputMaybe<Scalars['String']>;
  card_token: Scalars['String'];
  customer_ip: Scalars['String'];
  id: Scalars['ID'];
  status?: InputMaybe<Scalars['String']>;
};

export type CardPurchaseDataPoc = {
  card_token: Scalars['String'];
  customer_ip: Scalars['String'];
};

export type CashPurchaseData = {
  email?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

export enum CompanyType {
  Cooperative = 'Cooperative',
  Corporation = 'Corporation',
  Partnership = 'Partnership',
  Sole = 'Sole',
  UnitTrust = 'UnitTrust'
}

/** Merchant can set the convenienceFee only. */
export type ControlledUpdateMerchantSettingData = {
  convenienceFee?: InputMaybe<Scalars['Float']>;
};

export type Conversation = {
  __typename?: 'Conversation';
  businessLogo?: Maybe<Scalars['String']>;
  businessName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  memberDetails?: Maybe<Array<Maybe<ConversationMember>>>;
  members?: Maybe<Array<Maybe<User>>>;
  messages?: Maybe<Array<Maybe<Message>>>;
  order?: Maybe<Order>;
  orderId?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type ConversationMember = {
  __typename?: 'ConversationMember';
  joinedAt?: Maybe<Scalars['String']>;
  lastReadTimestamp?: Maybe<Scalars['String']>;
  userId: Scalars['ID'];
};

export type ConversationSubscriptionType = {
  __typename?: 'ConversationSubscriptionType';
  mutation?: Maybe<MutationType>;
  newMessageDetails?: Maybe<NewMessageDetails>;
};

export type DataOrderType = {
  orderId: Scalars['ID'];
  paymentMethod: Scalars['String'];
};

export type EventType = {
  __typename?: 'EventType';
  business?: Maybe<Business>;
  createdAt?: Maybe<Scalars['DateTime']>;
  disabledAt?: Maybe<Scalars['DateTime']>;
  endDateTime?: Maybe<Scalars['DateTime']>;
  formattedAddress?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  images?: Maybe<Array<Maybe<ProductImage>>>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  qrCode?: Maybe<Scalars['String']>;
  redirectUrl?: Maybe<Scalars['String']>;
  startDateTime?: Maybe<Scalars['DateTime']>;
  tipMax?: Maybe<Scalars['Float']>;
  tipMin?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FatZebraReturnType = {
  __typename?: 'FatZebraReturnType';
  amount?: Maybe<Scalars['Float']>;
  authorization?: Maybe<Scalars['String']>;
  captured?: Maybe<Scalars['Boolean']>;
  captured_amount?: Maybe<Scalars['Float']>;
  card_category?: Maybe<Scalars['String']>;
  card_expiry?: Maybe<Scalars['String']>;
  card_holder?: Maybe<Scalars['String']>;
  card_number?: Maybe<Scalars['String']>;
  card_subcategory?: Maybe<Scalars['String']>;
  card_token?: Maybe<Scalars['String']>;
  card_type?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  cvv_match?: Maybe<Scalars['String']>;
  decimal_amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
  rrn?: Maybe<Scalars['String']>;
  settlement_date?: Maybe<Scalars['String']>;
  successful?: Maybe<Scalars['Boolean']>;
  transaction_date?: Maybe<Scalars['DateTime']>;
  transaction_id?: Maybe<Scalars['String']>;
};

export type FatzeZebraIframeUrl = {
  __typename?: 'FatzeZebraIframeURL';
  fullUrl?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type FileUploadResponse = {
  __typename?: 'FileUploadResponse';
  Bucket: Scalars['String'];
  ETag: Scalars['String'];
  Location: Scalars['String'];
  key: Scalars['String'];
};

export type FiservResponseType = {
  __typename?: 'FiservResponseType';
  url?: Maybe<Scalars['String']>;
};

export type GcashCheckoutDetails = {
  __typename?: 'GcashCheckoutDetails';
  checkoutUrl?: Maybe<Scalars['String']>;
  gcashTransId?: Maybe<Scalars['Int']>;
  merchantTransId?: Maybe<Scalars['String']>;
  order?: Maybe<Order>;
  resultCode?: Maybe<Scalars['String']>;
};

export type GcashCheckoutUrl = {
  __typename?: 'GcashCheckoutUrl';
  checkoutUrl?: Maybe<Scalars['String']>;
  gcashTransId?: Maybe<Scalars['Int']>;
  merchantTransId?: Maybe<Scalars['String']>;
  resultCode?: Maybe<Scalars['String']>;
};

export type GcashPaymentData = {
  accountNumber?: InputMaybe<Scalars['String']>;
  amount?: InputMaybe<Scalars['Float']>;
  billerId: Scalars['ID'];
};

export type GcashPurchaseData = {
  amount: Scalars['Float'];
  id: Scalars['ID'];
  status?: InputMaybe<Scalars['String']>;
};

export type ItemData = {
  productId: Scalars['ID'];
  quantity?: InputMaybe<Scalars['Int']>;
  variations?: InputMaybe<Array<InputMaybe<VariationInput>>>;
};

export type LoginResponse = {
  __typename?: 'LoginResponse';
  role?: Maybe<Scalars['String']>;
  tokens?: Maybe<Token>;
};

export type MerchantCountWithStatus = {
  __typename?: 'MerchantCountWithStatus';
  count?: Maybe<Scalars['Int']>;
  typeBreakdown?: Maybe<Array<Maybe<TypeBreakdown>>>;
};

export type MerchantDashboardNumberOfOrders = {
  __typename?: 'MerchantDashboardNumberOfOrders';
  claimed?: Maybe<Scalars['Int']>;
  totalOrders?: Maybe<Scalars['Int']>;
  unclaimed?: Maybe<Scalars['Int']>;
};

export type MerchantDashboardTotalSales = {
  __typename?: 'MerchantDashboardTotalSales';
  date?: Maybe<Scalars['String']>;
  sales?: Maybe<Scalars['Float']>;
};

export type MerchantDashboardTotalSalesWithSum = {
  __typename?: 'MerchantDashboardTotalSalesWithSum';
  dashboardTotalSales?: Maybe<Array<Maybe<MerchantDashboardTotalSales>>>;
  totalSales?: Maybe<Scalars['Float']>;
};

export type MerchantDetail = {
  __typename?: 'MerchantDetail';
  bankAccount?: Maybe<BankAccount>;
  documents?: Maybe<Array<Maybe<MerchantDocument>>>;
  profile?: Maybe<User>;
  settings?: Maybe<MerchantSetting>;
};

export type MerchantDocument = {
  __typename?: 'MerchantDocument';
  filename?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  isCurrent?: Maybe<Scalars['Boolean']>;
  signedS3Url?: Maybe<Scalars['String']>;
};

export enum MerchantEnum {
  Biller = 'biller',
  Marketplace = 'marketplace'
}

export type MerchantEvents = {
  __typename?: 'MerchantEvents';
  events?: Maybe<Array<Maybe<EventType>>>;
  page?: Maybe<Scalars['Int']>;
  pages?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
};

export type MerchantListData = {
  __typename?: 'MerchantListData';
  data?: Maybe<Array<Maybe<User>>>;
  itemCount?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  pageCount?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
};

export type MerchantListDataJlt = {
  __typename?: 'MerchantListDataJlt';
  data?: Maybe<Array<Maybe<Business>>>;
  itemCount?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  pageCount?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
};

export type MerchantLists = {
  __typename?: 'MerchantLists';
  approved?: Maybe<MerchantListData>;
  pending?: Maybe<MerchantListData>;
};


export type MerchantListsApprovedArgs = {
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
};


export type MerchantListsPendingArgs = {
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
};

export type MerchantListsJlt = {
  __typename?: 'MerchantListsJlt';
  approved?: Maybe<MerchantListDataJlt>;
  inactive?: Maybe<MerchantListDataJlt>;
  pending?: Maybe<MerchantListDataJlt>;
  total?: Maybe<Scalars['Int']>;
};


export type MerchantListsJltApprovedArgs = {
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
};


export type MerchantListsJltInactiveArgs = {
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
};


export type MerchantListsJltPendingArgs = {
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
};

export type MerchantOrders = {
  __typename?: 'MerchantOrders';
  orders?: Maybe<Array<Maybe<MerchantSpecificOrder>>>;
  page?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
};

export type MerchantProductImagesFile = {
  file: Scalars['Upload'];
  id?: InputMaybe<Scalars['Int']>;
};

export type MerchantProductVariationOptions = {
  choiceName?: InputMaybe<Scalars['String']>;
  extraCost?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['Int']>;
};

export type MerchantProductVariations = {
  heading?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  maxChoices?: InputMaybe<Scalars['Int']>;
  minChoices?: InputMaybe<Scalars['Int']>;
  options?: InputMaybe<Array<InputMaybe<MerchantProductVariationOptions>>>;
  required?: InputMaybe<Scalars['Boolean']>;
};

export type MerchantProducts = {
  __typename?: 'MerchantProducts';
  page?: Maybe<Scalars['Int']>;
  products?: Maybe<Array<Maybe<Product>>>;
  total: Scalars['Int'];
};

export type MerchantProfileType = {
  contactNumber?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
};

/**
 * Holds the data related to transaction fees assigned to a specific merchant.
 *
 * Example:
 *
 * {
 *
 *     id:"1",
 *     userId:"1",
 *     serviceFee:"0.15",
 *     convenienceFee:"20.00",
 *     convenienceFeeEnabled:true,
 *     serviceFeeEnabled:false,
 *     transactionLimit:99999999.99
 *     createdAt:2023-01-01 01:01:01,
 *     updatedAt:2023-01-01 01:01:01
 *
 * }
 */
export type MerchantSetting = {
  __typename?: 'MerchantSetting';
  /** ConvenienceFee is the fixed amount that the merchant will pay to the App per transaction. The minimum value is set originally to 10.00. */
  convenienceFee?: Maybe<Scalars['Float']>;
  /** When set to true the convenienceFee is added to the total amount that the patron will pay per transaction. When set to false, the convenienceFee will NOT be added to the patron's total payment. */
  convenienceFeeEnabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  /** ServiceFee is the percentage of the transaction that will be paid to the App. The default value is originally set to 0.05 or 5%. */
  serviceFee?: Maybe<Scalars['Float']>;
  /** When set to true the serviceFee is added to the total amount that the patron will pay per transaction. */
  serviceFeeEnabled?: Maybe<Scalars['Boolean']>;
  /** The maximum amount per transaction for a merchant. Originally set to 99,999,999.99 */
  transactionLimit?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId: Scalars['ID'];
};

export type MerchantSpecificOrder = {
  __typename?: 'MerchantSpecificOrder';
  amount?: Maybe<Scalars['Float']>;
  business?: Maybe<Business>;
  businessId: Scalars['ID'];
  cashPaymentConfirmed?: Maybe<Scalars['Boolean']>;
  convenienceFee?: Maybe<Scalars['Float']>;
  conversation?: Maybe<Conversation>;
  conversationId?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customerEmail?: Maybe<Scalars['String']>;
  customerPhoneNumber?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isClaimed?: Maybe<Scalars['Boolean']>;
  isSeen?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  netAmountToVendorsBank?: Maybe<Scalars['Float']>;
  orderItems?: Maybe<Array<Maybe<MerchantSpecificOrderItem>>>;
  orderStatus?: Maybe<OrderStatusEnum>;
  patron?: Maybe<User>;
  paymentMethod?: Maybe<Scalars['String']>;
  referenceId?: Maybe<Scalars['String']>;
  serviceFee?: Maybe<Scalars['Float']>;
  specialInstructions?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  subTotal?: Maybe<Scalars['Float']>;
  totalAppFees?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vat?: Maybe<Scalars['Float']>;
};

export type MerchantSpecificOrderItem = {
  __typename?: 'MerchantSpecificOrderItem';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  images?: Maybe<Array<Maybe<OrderItemImage>>>;
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  product?: Maybe<Product>;
  productId: Scalars['ID'];
  quantity?: Maybe<Scalars['Int']>;
  variations?: Maybe<Scalars['String']>;
};

export type MerchantStaffListResponse = {
  __typename?: 'MerchantStaffListResponse';
  page?: Maybe<Scalars['Int']>;
  pages?: Maybe<Scalars['Int']>;
  staffList?: Maybe<Array<Maybe<User>>>;
  totalCount?: Maybe<Scalars['Int']>;
};

export enum MerchantType {
  Biller = 'biller',
  Merchant = 'merchant'
}

export type Message = {
  __typename?: 'Message';
  content?: Maybe<Scalars['String']>;
  conversationId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  sender?: Maybe<User>;
  senderId?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  admin?: Maybe<MutationAdmin>;
  archiveDocument?: Maybe<Scalars['Int']>;
  createAdmin: User;
  createConversationAboutOrder?: Maybe<Conversation>;
  deleteConversation?: Maybe<Conversation>;
  fileUpload: FileUploadResponse;
  fileUploadMany: Array<Maybe<FileUploadResponse>>;
  generateAndSendEmailOtp: Scalars['Boolean'];
  getStripeConnectAccountLink: Scalars['String'];
  increment?: Maybe<Scalars['Int']>;
  login: LoginResponse;
  markConversationRead?: Maybe<Scalars['Boolean']>;
  me?: Maybe<MutationUser>;
  merchant?: Maybe<MutationMerchant>;
  notifications?: Maybe<MutationNotification>;
  patron?: Maybe<MutationPatron>;
  payPoc?: Maybe<Scalars['Boolean']>;
  refreshToken?: Maybe<UserAuthenticationTokens>;
  register: User;
  registerBusiness: User;
  retrieveStripeCheckoutSession: StripeCheckoutSessionType;
  sendMessage?: Maybe<Message>;
  test: Scalars['String'];
  user?: Maybe<MutationUser>;
  verifyEmailOtp: Scalars['Boolean'];
};


export type MutationArchiveDocumentArgs = {
  documentIds?: InputMaybe<Array<Scalars['ID']>>;
};


export type MutationCreateAdminArgs = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  password: Scalars['String'];
};


export type MutationCreateConversationAboutOrderArgs = {
  orderId: Scalars['String'];
};


export type MutationDeleteConversationArgs = {
  id?: InputMaybe<Scalars['String']>;
};


export type MutationFileUploadArgs = {
  file: Scalars['Upload'];
};


export type MutationFileUploadManyArgs = {
  file: Array<InputMaybe<Scalars['Upload']>>;
};


export type MutationGenerateAndSendEmailOtpArgs = {
  email: Scalars['String'];
};


export type MutationGetStripeConnectAccountLinkArgs = {
  requestRestartOnboarding?: InputMaybe<Scalars['Boolean']>;
};


export type MutationLoginArgs = {
  email: Scalars['String'];
  isKeepLoggedIn?: InputMaybe<Scalars['Boolean']>;
  isPatron?: InputMaybe<Scalars['Boolean']>;
  isVerify?: InputMaybe<Scalars['Boolean']>;
  password: Scalars['String'];
};


export type MutationMarkConversationReadArgs = {
  conversationId: Scalars['String'];
};


export type MutationPayPocArgs = {
  data: CardPurchaseDataPoc;
};


export type MutationRefreshTokenArgs = {
  refreshToken?: InputMaybe<Scalars['String']>;
};


export type MutationRegisterArgs = {
  contactNumber: Scalars['String'];
  email: Scalars['String'];
  firebaseUid: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  userType: Scalars['String'];
};


export type MutationRegisterBusinessArgs = {
  abn: Scalars['String'];
  accountName: Scalars['String'];
  accountNumber: Scalars['String'];
  bankAddress?: InputMaybe<Scalars['String']>;
  bankName: Scalars['String'];
  banner?: InputMaybe<Scalars['Upload']>;
  bsb: Scalars['String'];
  businessName: Scalars['String'];
  companyType: CompanyType;
  files?: InputMaybe<Array<Scalars['Upload']>>;
  formattedAddress?: InputMaybe<Scalars['String']>;
  logo?: InputMaybe<Scalars['Upload']>;
  merchantType: Scalars['String'];
};


export type MutationRetrieveStripeCheckoutSessionArgs = {
  sessionId: Scalars['String'];
};


export type MutationSendMessageArgs = {
  content: Scalars['String'];
  conversationId: Scalars['String'];
};


export type MutationUserArgs = {
  uid?: InputMaybe<Scalars['String']>;
};


export type MutationVerifyEmailOtpArgs = {
  email: Scalars['String'];
  otp: Scalars['String'];
};

export type MutationAdmin = {
  __typename?: 'MutationAdmin';
  addAdminAccount?: Maybe<User>;
  /** Purpose: Add a new set of appSettings. */
  addAppSetting?: Maybe<Array<Maybe<AppSetting>>>;
  addVendor?: Maybe<User>;
  approve?: Maybe<User>;
  createBusinessType?: Maybe<BusinessType>;
  createEvent?: Maybe<EventType>;
  /** Purpose: Delete an existing appSetting. Cannot delete protected appSettings related to fees (e.g., defaultServiceFees). */
  deleteAppSetting?: Maybe<AppSetting>;
  disable?: Maybe<User>;
  enable?: Maybe<User>;
  generateReport?: Maybe<Scalars['String']>;
  guide: Scalars['String'];
  productImagesAdd?: Maybe<Array<Maybe<ProductImage>>>;
  productImagesRemove?: Maybe<Array<Maybe<ProductImage>>>;
  removeAdminAccount?: Maybe<User>;
  resolveUpdateRefundAdmin?: Maybe<Order>;
  revokeBusinessVerification?: Maybe<User>;
  toggleMaintenanceMode?: Maybe<AppSetting>;
  updateAdminAccount?: Maybe<User>;
  /** Purpose: Change or alter existing appSettings. Only the value field can be updated. */
  updateAppSetting?: Maybe<Array<Maybe<AppSetting>>>;
  updateBusinessType?: Maybe<BusinessType>;
  updateMerchantDetailsBusking?: Maybe<Scalars['Boolean']>;
  updateMerchantProduct?: Maybe<Product>;
  /** Purpose: Admin can use updateMerchantSettings to update the merchant settings for a single merchant. */
  updateMerchantSettings?: Maybe<MerchantSetting>;
  updateSettlement?: Maybe<Scalars['Boolean']>;
};


export type MutationAdminAddAdminAccountArgs = {
  contactNumber: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  permissions: Array<Scalars['String']>;
};


export type MutationAdminAddAppSettingArgs = {
  settings?: InputMaybe<Array<AppSettingInputData>>;
};


export type MutationAdminAddVendorArgs = {
  abn: Scalars['String'];
  city?: InputMaybe<Scalars['String']>;
  contactNumber?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  formattedAddress: Scalars['String'];
  lastName: Scalars['String'];
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  merchantType: Scalars['String'];
  street?: InputMaybe<Scalars['String']>;
  zipcode?: InputMaybe<Scalars['String']>;
};


export type MutationAdminApproveArgs = {
  convenienceFee?: InputMaybe<Scalars['Float']>;
  serviceFee?: InputMaybe<Scalars['Float']>;
  uid?: InputMaybe<Scalars['String']>;
};


export type MutationAdminCreateBusinessTypeArgs = {
  data: BusinessTypeData;
};


export type MutationAdminCreateEventArgs = {
  businessId?: InputMaybe<Scalars['ID']>;
  endDateTime: Scalars['String'];
  eventName: Scalars['String'];
  formattedAddress: Scalars['String'];
  images?: InputMaybe<Array<Scalars['Upload']>>;
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  maximumTip: Scalars['Float'];
  minimumTip: Scalars['Float'];
  publish?: InputMaybe<Scalars['Boolean']>;
  startDateTime: Scalars['String'];
};


export type MutationAdminDeleteAppSettingArgs = {
  id: Scalars['Int'];
};


export type MutationAdminDisableArgs = {
  reasons?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  uid?: InputMaybe<Scalars['String']>;
};


export type MutationAdminEnableArgs = {
  uid?: InputMaybe<Scalars['String']>;
};


export type MutationAdminGenerateReportArgs = {
  data?: InputMaybe<Array<InputMaybe<OrderAsInputType>>>;
};


export type MutationAdminProductImagesAddArgs = {
  files?: InputMaybe<Array<Scalars['Upload']>>;
  productId: Scalars['Int'];
};


export type MutationAdminProductImagesRemoveArgs = {
  id: Scalars['Int'];
  images?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};


export type MutationAdminRemoveAdminAccountArgs = {
  userId: Scalars['ID'];
};


export type MutationAdminResolveUpdateRefundAdminArgs = {
  orderId: Scalars['Int'];
  refundStatus: Scalars['String'];
};


export type MutationAdminRevokeBusinessVerificationArgs = {
  uid: Scalars['ID'];
};


export type MutationAdminToggleMaintenanceModeArgs = {
  setToMaintMode: Scalars['Boolean'];
};


export type MutationAdminUpdateAdminAccountArgs = {
  isAddPermissions?: InputMaybe<Scalars['Boolean']>;
  isRemovePermissions?: InputMaybe<Scalars['Boolean']>;
  permissions?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  profileData?: InputMaybe<ProfileDataType>;
  userId: Scalars['ID'];
};


export type MutationAdminUpdateAppSettingArgs = {
  settings?: InputMaybe<Array<UpdateAppSettingInputData>>;
};


export type MutationAdminUpdateBusinessTypeArgs = {
  data?: InputMaybe<UpdateBusinessType>;
  icon?: InputMaybe<Scalars['Upload']>;
  id: Scalars['ID'];
};


export type MutationAdminUpdateMerchantDetailsBuskingArgs = {
  data?: InputMaybe<BuskingMerchantDetailsData>;
  uid: Scalars['ID'];
};


export type MutationAdminUpdateMerchantProductArgs = {
  description?: InputMaybe<Scalars['String']>;
  disabled?: InputMaybe<Scalars['Boolean']>;
  endDateTime?: InputMaybe<Scalars['DateTime']>;
  formattedAddress?: InputMaybe<Scalars['String']>;
  latitude?: InputMaybe<Scalars['Float']>;
  longitude?: InputMaybe<Scalars['Float']>;
  name?: InputMaybe<Scalars['String']>;
  parLevel?: InputMaybe<Scalars['Int']>;
  price?: InputMaybe<Scalars['Float']>;
  productId: Scalars['ID'];
  startDateTime?: InputMaybe<Scalars['DateTime']>;
  stock?: InputMaybe<Scalars['Int']>;
  tipMax?: InputMaybe<Scalars['Float']>;
  tipMin?: InputMaybe<Scalars['Float']>;
};


export type MutationAdminUpdateMerchantSettingsArgs = {
  data?: InputMaybe<UpdateMerchantSettingData>;
  uid?: InputMaybe<Scalars['String']>;
};


export type MutationAdminUpdateSettlementArgs = {
  settlementIds?: InputMaybe<Array<Scalars['ID']>>;
  status: Scalars['String'];
};

export type MutationMerchant = {
  __typename?: 'MutationMerchant';
  addMerchantStaff?: Maybe<User>;
  createEvent?: Maybe<EventType>;
  deleteProduct?: Maybe<Product>;
  guide: Scalars['String'];
  merchantCreateOrderOnBehalf?: Maybe<Order>;
  merchantMarkOrdersAsSeen?: Maybe<Scalars['Boolean']>;
  merchantOrderConfirmCashPayment?: Maybe<Scalars['Boolean']>;
  merchantOrderUpdate?: Maybe<Order>;
  pauseOrders?: Maybe<PauseOrdersResponse>;
  productCreate?: Maybe<Product>;
  productImages?: Maybe<Array<Maybe<ProductImage>>>;
  productImagesAdd?: Maybe<Array<Maybe<ProductImage>>>;
  productImagesRemove?: Maybe<Array<Maybe<ProductImage>>>;
  productUpdate?: Maybe<Product>;
  setBusinessHours?: Maybe<SetBusHoursResponseType>;
  setBusinessSchedules?: Maybe<Business>;
  setToSoldOut?: Maybe<Product>;
  toggleManualOpenCloseUpdate?: Maybe<Business>;
  updateBanner?: Maybe<BusinessBannerType>;
  updateLogo?: Maybe<BusinessLogoType>;
  updateMerchantDetails?: Maybe<UpdatedData>;
  /** Purpose: Merchant may set the value for convenienceFee. */
  updateMerchantSettings?: Maybe<MerchantSetting>;
  updateMerchantStaff?: Maybe<User>;
};


export type MutationMerchantAddMerchantStaffArgs = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  permission: Scalars['String'];
};


export type MutationMerchantCreateEventArgs = {
  endDateTime: Scalars['String'];
  eventName: Scalars['String'];
  formattedAddress: Scalars['String'];
  images?: InputMaybe<Array<Scalars['Upload']>>;
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  maximumTip: Scalars['Float'];
  minimumTip: Scalars['Float'];
  startDateTime: Scalars['String'];
};


export type MutationMerchantDeleteProductArgs = {
  productId: Scalars['ID'];
};


export type MutationMerchantMerchantCreateOrderOnBehalfArgs = {
  data: CreateOrderOnBehalfData;
};


export type MutationMerchantMerchantMarkOrdersAsSeenArgs = {
  orderIds: Array<Scalars['ID']>;
};


export type MutationMerchantMerchantOrderConfirmCashPaymentArgs = {
  cashPaymentConfirmed: Scalars['Boolean'];
  orderId: Scalars['ID'];
};


export type MutationMerchantMerchantOrderUpdateArgs = {
  data?: InputMaybe<OrderUpdateData>;
  orderId: Scalars['ID'];
};


export type MutationMerchantPauseOrdersArgs = {
  pause: Scalars['Boolean'];
};


export type MutationMerchantProductCreateArgs = {
  category?: InputMaybe<Scalars['String']>;
  description: Scalars['String'];
  disabled?: InputMaybe<Scalars['Boolean']>;
  endDateTime?: InputMaybe<Scalars['String']>;
  images?: InputMaybe<Array<InputMaybe<Scalars['Upload']>>>;
  name: Scalars['String'];
  parLevel?: InputMaybe<Scalars['Int']>;
  price: Scalars['Float'];
  startDateTime?: InputMaybe<Scalars['String']>;
  stock?: InputMaybe<Scalars['Int']>;
  variations?: InputMaybe<Array<InputMaybe<MerchantProductVariations>>>;
};


export type MutationMerchantProductImagesArgs = {
  files?: InputMaybe<Array<InputMaybe<MerchantProductImagesFile>>>;
  id: Scalars['Int'];
};


export type MutationMerchantProductImagesAddArgs = {
  files?: InputMaybe<Array<Scalars['Upload']>>;
  productId: Scalars['Int'];
};


export type MutationMerchantProductImagesRemoveArgs = {
  id: Scalars['Int'];
  images?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};


export type MutationMerchantProductUpdateArgs = {
  category?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  disabled?: InputMaybe<Scalars['Boolean']>;
  endDateTime?: InputMaybe<Scalars['String']>;
  formattedAddress?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  latitude?: InputMaybe<Scalars['Float']>;
  longitude?: InputMaybe<Scalars['Float']>;
  name?: InputMaybe<Scalars['String']>;
  parLevel?: InputMaybe<Scalars['Int']>;
  price?: InputMaybe<Scalars['Float']>;
  startDateTime?: InputMaybe<Scalars['String']>;
  stock?: InputMaybe<Scalars['Int']>;
  tipMax?: InputMaybe<Scalars['Float']>;
  tipMin?: InputMaybe<Scalars['Float']>;
  variations?: InputMaybe<Array<InputMaybe<MerchantProductVariations>>>;
};


export type MutationMerchantSetBusinessHoursArgs = {
  data?: InputMaybe<BusinessHours>;
};


export type MutationMerchantSetBusinessSchedulesArgs = {
  schedules?: InputMaybe<Array<InputMaybe<BusinessSchedule>>>;
};


export type MutationMerchantSetToSoldOutArgs = {
  isSoldOut?: InputMaybe<Scalars['Boolean']>;
  productId: Scalars['ID'];
};


export type MutationMerchantToggleManualOpenCloseUpdateArgs = {
  isManual?: InputMaybe<Scalars['Boolean']>;
};


export type MutationMerchantUpdateBannerArgs = {
  banner: Scalars['Upload'];
};


export type MutationMerchantUpdateLogoArgs = {
  logo: Scalars['Upload'];
};


export type MutationMerchantUpdateMerchantDetailsArgs = {
  data?: InputMaybe<BusinessDetails>;
};


export type MutationMerchantUpdateMerchantSettingsArgs = {
  data?: InputMaybe<ControlledUpdateMerchantSettingData>;
};


export type MutationMerchantUpdateMerchantStaffArgs = {
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  permission?: InputMaybe<Scalars['String']>;
  userId: Scalars['ID'];
};

export type MutationNotification = {
  __typename?: 'MutationNotification';
  readAllNotifications?: Maybe<QueryNotifications>;
  readNotification?: Maybe<NotificationData>;
  sendTestNotifications?: Maybe<Scalars['Boolean']>;
};


export type MutationNotificationReadAllNotificationsArgs = {
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
};


export type MutationNotificationReadNotificationArgs = {
  notificationId?: InputMaybe<Scalars['Int']>;
};


export type MutationNotificationSendTestNotificationsArgs = {
  fcmToken: Scalars['String'];
};

export type MutationPatron = {
  __typename?: 'MutationPatron';
  cardBillPayment?: Maybe<CardBillPaymentReturnType>;
  guide: Scalars['String'];
  purchase: FiservResponseType;
  requestRefund?: Maybe<Order>;
  resolveCardPurchase?: Maybe<Order>;
  resolveCashPurchase?: Maybe<Order>;
  resolveCreateVCAN?: Maybe<Scalars['String']>;
  resolveGcashPurchase?: Maybe<GcashCheckoutDetails>;
};


export type MutationPatronCardBillPaymentArgs = {
  data: CardBillPaymentData;
};


export type MutationPatronPurchaseArgs = {
  data: PurchaseData;
};


export type MutationPatronRequestRefundArgs = {
  orderId: Scalars['ID'];
  productIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  reason?: InputMaybe<Scalars['String']>;
};


export type MutationPatronResolveCardPurchaseArgs = {
  data: CardPurchaseData;
};


export type MutationPatronResolveCashPurchaseArgs = {
  data: PurchaseData;
};


export type MutationPatronResolveCreateVcanArgs = {
  data: BillsPaymentData;
};


export type MutationPatronResolveGcashPurchaseArgs = {
  data: GcashPurchaseData;
};

export enum MutationType {
  Created = 'CREATED',
  Deleted = 'DELETED',
  Updated = 'UPDATED'
}

export type MutationUser = {
  __typename?: 'MutationUser';
  update?: Maybe<User>;
};


export type MutationUserUpdateArgs = {
  data: UserData;
  file?: InputMaybe<Scalars['Upload']>;
};

export type NetBankNotifications = {
  __typename?: 'NetBankNotifications';
  amount?: Maybe<Scalars['Float']>;
  channel?: Maybe<Scalars['String']>;
  externalTransferStatus?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  operationId?: Maybe<Scalars['String']>;
  productBranchCode?: Maybe<Scalars['String']>;
  recipientAccountNumber?: Maybe<Scalars['String']>;
  recipientAccountNumberBankFormat?: Maybe<Scalars['String']>;
  referenceCode?: Maybe<Scalars['String']>;
  referenceNumber?: Maybe<Scalars['String']>;
  registrationTime?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  senderAccountNumber?: Maybe<Scalars['String']>;
  senderInstitutionCode?: Maybe<Scalars['String']>;
  senderName?: Maybe<Scalars['String']>;
  transferType?: Maybe<Scalars['String']>;
};

export type NetBankNotificationsResult = {
  __typename?: 'NetBankNotificationsResult';
  data?: Maybe<Array<Maybe<NetBankNotifications>>>;
  itemCount?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  pageCount?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
};

export type NewMessageDetails = {
  __typename?: 'NewMessageDetails';
  conversationId?: Maybe<Scalars['String']>;
  members?: Maybe<Array<Maybe<Scalars['String']>>>;
  message?: Maybe<Message>;
};

export type NotificationData = {
  __typename?: 'NotificationData';
  body?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Int']>;
  isRead?: Maybe<Scalars['String']>;
  sentAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  uid?: Maybe<Scalars['Int']>;
};

export type Order = {
  __typename?: 'Order';
  amount?: Maybe<Scalars['Float']>;
  business?: Maybe<Business>;
  businessId: Scalars['ID'];
  cashPaymentConfirmed?: Maybe<Scalars['Boolean']>;
  convenienceFee?: Maybe<Scalars['Float']>;
  conversation?: Maybe<Conversation>;
  conversationId?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customerEmail?: Maybe<Scalars['String']>;
  customerPhoneNumber?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isClaimed?: Maybe<Scalars['Boolean']>;
  isSeen?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  netAmountToVendorsBank?: Maybe<Scalars['Float']>;
  orderItems?: Maybe<Array<Maybe<OrderItem>>>;
  orderStatus?: Maybe<OrderStatusEnum>;
  patron?: Maybe<User>;
  paymentMethod?: Maybe<Scalars['String']>;
  referenceId?: Maybe<Scalars['String']>;
  serviceFee?: Maybe<Scalars['Float']>;
  specialInstructions?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  subTotal?: Maybe<Scalars['Float']>;
  totalAppFees?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vat?: Maybe<Scalars['Float']>;
};

export type OrderAsInputType = {
  amount?: InputMaybe<Scalars['Float']>;
  cashPaymentConfirmed?: InputMaybe<Scalars['Boolean']>;
  convenienceFee?: InputMaybe<Scalars['Float']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  customerEmail?: InputMaybe<Scalars['String']>;
  customerPhoneNumber?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  isClaimed?: InputMaybe<Scalars['Boolean']>;
  lastName?: InputMaybe<Scalars['String']>;
  orderStatus?: InputMaybe<Scalars['String']>;
  paymentMethod?: InputMaybe<Scalars['String']>;
  referenceId?: InputMaybe<Scalars['String']>;
  serviceFee?: InputMaybe<Scalars['Float']>;
  status?: InputMaybe<Scalars['String']>;
  subTotal?: InputMaybe<Scalars['Float']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  vat?: InputMaybe<Scalars['Float']>;
};

export type OrderData = {
  amount?: InputMaybe<Scalars['Float']>;
  items?: InputMaybe<Array<InputMaybe<ItemData>>>;
  merchantId: Scalars['ID'];
  paymentMethod?: InputMaybe<Scalars['String']>;
  type: MerchantEnum;
  variations?: InputMaybe<Array<InputMaybe<VariationInput>>>;
};

export type OrderDetails = {
  __typename?: 'OrderDetails';
  jsonField?: Maybe<Scalars['String']>;
};

export type OrderItem = {
  __typename?: 'OrderItem';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  images?: Maybe<Array<Maybe<OrderItemImage>>>;
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  product?: Maybe<Product>;
  productId: Scalars['ID'];
  quantity?: Maybe<Scalars['Int']>;
  variations?: Maybe<Array<Maybe<ProductVariation>>>;
};

export type OrderItemImage = {
  __typename?: 'OrderItemImage';
  filepath?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  orderItem?: Maybe<OrderItem>;
};

export enum OrderStatusEnum {
  Claimed = 'claimed',
  Pending = 'pending',
  Ready = 'ready'
}

export type OrderSubscriptionType = {
  __typename?: 'OrderSubscriptionType';
  mutation?: Maybe<MutationType>;
  order?: Maybe<Order>;
};

export type OrderSummary = {
  __typename?: 'OrderSummary';
  orderedItems?: Maybe<Array<Maybe<OrderedItems>>>;
  subTotal?: Maybe<Scalars['Float']>;
};

export type OrderUpdateData = {
  isClaimed?: InputMaybe<Scalars['Boolean']>;
  isRefund?: InputMaybe<Scalars['Boolean']>;
  orderStatus?: InputMaybe<OrderStatusEnum>;
  refundStatus?: InputMaybe<Scalars['String']>;
};

export type OrderedItems = {
  __typename?: 'OrderedItems';
  extraCost?: Maybe<Scalars['Float']>;
  orderedQty?: Maybe<Scalars['Int']>;
  product?: Maybe<Product>;
};

export type PatronMerchantProducts = {
  __typename?: 'PatronMerchantProducts';
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  products?: Maybe<Array<Maybe<Product>>>;
  total?: Maybe<Scalars['Int']>;
};

export type PatronMerchantProductsSearch = {
  __typename?: 'PatronMerchantProductsSearch';
  data?: Maybe<Array<Maybe<Business>>>;
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
};

export type PatronMerchantProductsSearchV1 = {
  __typename?: 'PatronMerchantProductsSearchV1';
  data?: Maybe<Array<Maybe<Product>>>;
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
};

export type PatronMerchants = {
  __typename?: 'PatronMerchants';
  merchants?: Maybe<Array<Maybe<Business>>>;
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
};

export type PatronOrderTotal = {
  __typename?: 'PatronOrderTotal';
  computedFee?: Maybe<Scalars['Float']>;
  convenienceFee?: Maybe<Scalars['Float']>;
  orderSummary?: Maybe<OrderSummary>;
  serviceFee?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
  type: MerchantEnum;
  vat?: Maybe<Scalars['Float']>;
};

export type PatronOrders = {
  __typename?: 'PatronOrders';
  orders?: Maybe<Array<Maybe<Order>>>;
  page?: Maybe<Scalars['Int']>;
  total: Scalars['Int'];
};

export type PauseOrdersResponse = {
  __typename?: 'PauseOrdersResponse';
  isAcceptingOrders?: Maybe<Scalars['Boolean']>;
};

export type Product = {
  __typename?: 'Product';
  business?: Maybe<Business>;
  category?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  disabledAt?: Maybe<Scalars['DateTime']>;
  endDateTime?: Maybe<Scalars['DateTime']>;
  formattedAddress?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  images?: Maybe<Array<Maybe<ProductImage>>>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  parLevel?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['String']>;
  qrCode?: Maybe<Scalars['String']>;
  redirectUrl?: Maybe<Scalars['String']>;
  startDateTime?: Maybe<Scalars['DateTime']>;
  stock?: Maybe<Scalars['Int']>;
  tipMax?: Maybe<Scalars['Float']>;
  tipMin?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  variations?: Maybe<Array<Maybe<ProductVariation>>>;
};

export type ProductBusinessReturnType = {
  __typename?: 'ProductBusinessReturnType';
  business?: Maybe<Business>;
  isProduct?: Maybe<Scalars['Boolean']>;
  product?: Maybe<Product>;
};

export type ProductImage = {
  __typename?: 'ProductImage';
  createdAt?: Maybe<Scalars['DateTime']>;
  filepath?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  product?: Maybe<Product>;
  productId?: Maybe<Scalars['Int']>;
  signedS3Url?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ProductSubscriptionType = {
  __typename?: 'ProductSubscriptionType';
  data: Product;
  mutation: MutationType;
};

export type ProductVariation = {
  __typename?: 'ProductVariation';
  createdAt?: Maybe<Scalars['DateTime']>;
  heading?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  maxChoices?: Maybe<Scalars['Int']>;
  minChoices?: Maybe<Scalars['Int']>;
  options?: Maybe<Array<Maybe<ProductVariationOption>>>;
  product?: Maybe<Product>;
  required?: Maybe<Scalars['Boolean']>;
  selected_options?: Maybe<Array<Maybe<Scalars['Int']>>>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  variation_id?: Maybe<Scalars['ID']>;
};

export type ProductVariationOption = {
  __typename?: 'ProductVariationOption';
  choiceName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  extraCost?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  productId?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  variationId?: Maybe<Scalars['Int']>;
};

export type ProfileDataType = {
  contactNumber?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
};

export type PurchaseData = {
  businessId: Scalars['ID'];
  items: Array<InputMaybe<ItemData>>;
  paymentMethod: Scalars['String'];
  specialInstructions?: InputMaybe<Scalars['String']>;
  tipAmount?: InputMaybe<Scalars['Float']>;
};

export type PurchaseHistoryResponseType = {
  __typename?: 'PurchaseHistoryResponseType';
  data?: Maybe<Array<Maybe<Order>>>;
  page?: Maybe<Scalars['Int']>;
  pages?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
};

export type Query = {
  __typename?: 'Query';
  admin?: Maybe<QueryAdmin>;
  getAllConversations?: Maybe<Array<Maybe<Conversation>>>;
  getConversationById?: Maybe<Conversation>;
  isMaintenanceMode?: Maybe<Scalars['Boolean']>;
  me?: Maybe<User>;
  merchant?: Maybe<QueryMerchant>;
  notifications?: Maybe<QueryNotifications>;
  patron?: Maybe<QueryPatron>;
  publicMerchant?: Maybe<User>;
  publicMerchantProducts?: Maybe<PatronMerchantProducts>;
  publicProductScan?: Maybe<Product>;
  publicProductSearchJlt?: Maybe<PatronMerchantProductsSearchV1>;
  publicProductsSearch?: Maybe<PatronMerchantProductsSearch>;
  publicSearchLocation?: Maybe<PatronMerchantProductsSearch>;
  settings?: Maybe<QuerySettings>;
};


export type QueryGetAllConversationsArgs = {
  messageLimit?: InputMaybe<Scalars['Int']>;
};


export type QueryGetConversationByIdArgs = {
  conversationId: Scalars['String'];
};


export type QueryPublicMerchantArgs = {
  businessId: Scalars['ID'];
};


export type QueryPublicMerchantProductsArgs = {
  businessId: Scalars['Int'];
  page?: InputMaybe<Scalars['Int']>;
  rows?: InputMaybe<Scalars['Int']>;
  searchString?: InputMaybe<Scalars['String']>;
};


export type QueryPublicProductScanArgs = {
  qrcode: Scalars['String'];
};


export type QueryPublicProductSearchJltArgs = {
  businessType: Scalars['String'];
  latitude?: InputMaybe<Scalars['Float']>;
  longitude?: InputMaybe<Scalars['Float']>;
  maxDistanceInputKm?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  rows?: InputMaybe<Scalars['Int']>;
  searchString?: InputMaybe<Scalars['String']>;
};


export type QueryPublicProductsSearchArgs = {
  businessType: Scalars['String'];
  latitude?: InputMaybe<Scalars['Float']>;
  longitude?: InputMaybe<Scalars['Float']>;
  maxDistanceInputKm?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  rows?: InputMaybe<Scalars['Int']>;
  searchString?: InputMaybe<Scalars['String']>;
};


export type QueryPublicSearchLocationArgs = {
  businessType?: InputMaybe<Scalars['String']>;
  latitude?: InputMaybe<Scalars['Float']>;
  longitude?: InputMaybe<Scalars['Float']>;
  maxDistanceInputKm?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  rows?: InputMaybe<Scalars['Int']>;
  searchString?: InputMaybe<Scalars['String']>;
};

export type QueryAdmin = {
  __typename?: 'QueryAdmin';
  adminAccount?: Maybe<User>;
  adminAccounts?: Maybe<QueryAdminAccountsResponseType>;
  /** Purpose: Return the specific appSetting values given an ID. */
  appSetting?: Maybe<AppSetting>;
  /** Purpose: Search the appSettings table and return a list of the available appSettings */
  appSettings?: Maybe<SettingsResult>;
  businessTypes?: Maybe<BusinessTypeResponseData>;
  event?: Maybe<EventType>;
  events?: Maybe<MerchantEvents>;
  guide: Scalars['String'];
  merchant?: Maybe<MerchantDetail>;
  merchantCount?: Maybe<AdminMerchantCount>;
  merchantList?: Maybe<MerchantLists>;
  merchantListJlt?: Maybe<MerchantListsJlt>;
  /** Purpose: Return the available merchantSettings given the user's ID */
  merchantSettings?: Maybe<MerchantSetting>;
  merchants?: Maybe<AdminMerchants>;
  netbankNotifications?: Maybe<NetBankNotificationsResult>;
  settlement?: Maybe<Settlement>;
  settlements?: Maybe<QuerySettlementsReturnType>;
  transaction?: Maybe<Order>;
  transactions?: Maybe<TransactionDataType>;
};


export type QueryAdminAdminAccountArgs = {
  userId?: InputMaybe<Scalars['String']>;
};


export type QueryAdminAdminAccountsArgs = {
  isSortAscending?: InputMaybe<Scalars['Boolean']>;
  page?: InputMaybe<Scalars['Int']>;
  rows?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<Scalars['String']>;
};


export type QueryAdminAppSettingArgs = {
  id?: InputMaybe<Scalars['Int']>;
};


export type QueryAdminAppSettingsArgs = {
  page?: InputMaybe<Scalars['Int']>;
  rows?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
};


export type QueryAdminBusinessTypesArgs = {
  page?: InputMaybe<Scalars['Int']>;
  rows?: InputMaybe<Scalars['Int']>;
};


export type QueryAdminEventArgs = {
  eventId?: InputMaybe<Scalars['ID']>;
};


export type QueryAdminEventsArgs = {
  businessId?: InputMaybe<Scalars['ID']>;
  isSortAscending?: InputMaybe<Scalars['Boolean']>;
  page?: InputMaybe<Scalars['Int']>;
  rows?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<Scalars['String']>;
};


export type QueryAdminMerchantArgs = {
  merchantType?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['Int']>;
};


export type QueryAdminMerchantListArgs = {
  merchantType?: InputMaybe<MerchantType>;
};


export type QueryAdminMerchantListJltArgs = {
  merchantType?: InputMaybe<Scalars['String']>;
};


export type QueryAdminMerchantSettingsArgs = {
  uid?: InputMaybe<Scalars['Int']>;
};


export type QueryAdminMerchantsArgs = {
  businessType?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
};


export type QueryAdminNetbankNotificationsArgs = {
  iperPage?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryAdminSettlementArgs = {
  settlementId: Scalars['ID'];
};


export type QueryAdminSettlementsArgs = {
  businessType?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['String']>;
  isSortAscending?: InputMaybe<Scalars['Boolean']>;
  page?: InputMaybe<Scalars['Int']>;
  pages?: InputMaybe<Scalars['Int']>;
  searchString?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
};


export type QueryAdminTransactionArgs = {
  orderId: Scalars['ID'];
};


export type QueryAdminTransactionsArgs = {
  businessType?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['String']>;
  isSortAscending?: InputMaybe<Scalars['Boolean']>;
  page?: InputMaybe<Scalars['Int']>;
  rows?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<SortByEnum>;
  startDate?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
};

export type QueryAdminAccountsResponseType = {
  __typename?: 'QueryAdminAccountsResponseType';
  data?: Maybe<Array<Maybe<User>>>;
  page?: Maybe<Scalars['Int']>;
  pages?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
};

export type QueryMerchant = {
  __typename?: 'QueryMerchant';
  billPayment?: Maybe<BillPaymentType>;
  billPayments?: Maybe<BillPayments>;
  businessTypes?: Maybe<BusinessTypeResponseData>;
  dashboardNumberOfOrders?: Maybe<MerchantDashboardNumberOfOrders>;
  dashboardTotalSales?: Maybe<MerchantDashboardTotalSalesWithSum>;
  event?: Maybe<EventType>;
  events?: Maybe<MerchantEvents>;
  guide: Scalars['String'];
  /** Purpose: Merchant may view his uploaded documents, bank details and profile. */
  merchantDetails?: Maybe<MerchantDetail>;
  merchantSettings?: Maybe<MerchantSetting>;
  merchantStaff?: Maybe<User>;
  merchantStaffList?: Maybe<MerchantStaffListResponse>;
  order?: Maybe<Order>;
  orderTotal?: Maybe<PatronOrderTotal>;
  orders?: Maybe<MerchantOrders>;
  ordersJlt?: Maybe<MerchantOrders>;
  product?: Maybe<Product>;
  products?: Maybe<MerchantProducts>;
  settlement?: Maybe<Settlement>;
  settlements?: Maybe<QuerySettlementsReturnType>;
  soldOutProducts?: Maybe<MerchantProducts>;
  transaction?: Maybe<Order>;
  transactions?: Maybe<TransactionDataType>;
};


export type QueryMerchantBillPaymentArgs = {
  billPaymentId: Scalars['ID'];
};


export type QueryMerchantBillPaymentsArgs = {
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
};


export type QueryMerchantBusinessTypesArgs = {
  page?: InputMaybe<Scalars['Int']>;
  rows?: InputMaybe<Scalars['Int']>;
};


export type QueryMerchantDashboardTotalSalesArgs = {
  endDate: Scalars['String'];
  isDaily?: InputMaybe<Scalars['Boolean']>;
  startDate: Scalars['String'];
};


export type QueryMerchantEventArgs = {
  eventId?: InputMaybe<Scalars['ID']>;
};


export type QueryMerchantEventsArgs = {
  isSortAscending?: InputMaybe<Scalars['Boolean']>;
  page?: InputMaybe<Scalars['Int']>;
  rows?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<Scalars['String']>;
};


export type QueryMerchantMerchantStaffArgs = {
  userId: Scalars['ID'];
};


export type QueryMerchantMerchantStaffListArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryMerchantOrderArgs = {
  orderId: Scalars['ID'];
};


export type QueryMerchantOrderTotalArgs = {
  data: OrderData;
};


export type QueryMerchantOrdersArgs = {
  order?: InputMaybe<Scalars['String']>;
  page: Scalars['Int'];
  rows: Scalars['Int'];
  search?: InputMaybe<Scalars['String']>;
};


export type QueryMerchantOrdersJltArgs = {
  orderStatus?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  page: Scalars['Int'];
  rows: Scalars['Int'];
  status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryMerchantProductArgs = {
  id: Scalars['Int'];
};


export type QueryMerchantProductsArgs = {
  page: Scalars['Int'];
  rows: Scalars['Int'];
  search: Scalars['String'];
};


export type QueryMerchantSettlementArgs = {
  settlementId: Scalars['ID'];
};


export type QueryMerchantSettlementsArgs = {
  businessType?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['String']>;
  isSortAscending?: InputMaybe<Scalars['Boolean']>;
  page?: InputMaybe<Scalars['Int']>;
  pages?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
};


export type QueryMerchantSoldOutProductsArgs = {
  page: Scalars['Int'];
  rows: Scalars['Int'];
};


export type QueryMerchantTransactionArgs = {
  orderId: Scalars['ID'];
};


export type QueryMerchantTransactionsArgs = {
  businessType?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['String']>;
  isSortAscending?: InputMaybe<Scalars['Boolean']>;
  page?: InputMaybe<Scalars['Int']>;
  rows?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<SortByEnum>;
  startDate?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
};

export type QueryNotification = {
  __typename?: 'QueryNotification';
  data?: Maybe<Array<Maybe<NotificationData>>>;
  itemCount?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  pageCount?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  unreadCount?: Maybe<Scalars['Int']>;
};

export type QueryNotifications = {
  __typename?: 'QueryNotifications';
  userNotifications?: Maybe<QueryNotification>;
};


export type QueryNotificationsUserNotificationsArgs = {
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
};

export type QueryPatron = {
  __typename?: 'QueryPatron';
  biller?: Maybe<User>;
  billerQRScan?: Maybe<User>;
  billers?: Maybe<BillerReturnType>;
  businessTypes?: Maybe<Array<Maybe<BusinessType>>>;
  fatzebraIframeUrl?: Maybe<FatzeZebraIframeUrl>;
  gcashCheckoutUrl?: Maybe<GcashCheckoutUrl>;
  guide: Scalars['String'];
  merchant?: Maybe<User>;
  merchantProducts?: Maybe<PatronMerchantProducts>;
  merchants?: Maybe<PatronMerchants>;
  order?: Maybe<Order>;
  orderTotal?: Maybe<PatronOrderTotal>;
  orders?: Maybe<PatronOrders>;
  productBusinessQRScan?: Maybe<ProductBusinessReturnType>;
  productScan?: Maybe<Product>;
  productSearchJlt?: Maybe<PatronMerchantProductsSearchV1>;
  productsSearch?: Maybe<PatronMerchantProductsSearch>;
  queryPurchase?: Maybe<Order>;
  queryPurchases?: Maybe<PurchaseHistoryResponseType>;
  searchLocation?: Maybe<PatronMerchantProductsSearch>;
};


export type QueryPatronBillerArgs = {
  userId?: InputMaybe<Scalars['Int']>;
};


export type QueryPatronBillerQrScanArgs = {
  qrCode: Scalars['String'];
};


export type QueryPatronBillersArgs = {
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
};


export type QueryPatronBusinessTypesArgs = {
  page?: InputMaybe<Scalars['Int']>;
  rows?: InputMaybe<Scalars['Int']>;
};


export type QueryPatronGcashCheckoutUrlArgs = {
  data: GcashPaymentData;
};


export type QueryPatronMerchantArgs = {
  businessId: Scalars['ID'];
};


export type QueryPatronMerchantProductsArgs = {
  businessId: Scalars['Int'];
  page?: InputMaybe<Scalars['Int']>;
  rows?: InputMaybe<Scalars['Int']>;
  searchString?: InputMaybe<Scalars['String']>;
};


export type QueryPatronMerchantsArgs = {
  businessType?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  rows?: InputMaybe<Scalars['Int']>;
};


export type QueryPatronOrderArgs = {
  orderReferenceId: Scalars['String'];
};


export type QueryPatronOrderTotalArgs = {
  data: OrderData;
};


export type QueryPatronOrdersArgs = {
  page: Scalars['Int'];
  paymentMethod: Scalars['String'];
  rows: Scalars['Int'];
  search: Scalars['String'];
};


export type QueryPatronProductBusinessQrScanArgs = {
  qrCode: Scalars['String'];
};


export type QueryPatronProductScanArgs = {
  qrcode: Scalars['String'];
};


export type QueryPatronProductSearchJltArgs = {
  businessType?: InputMaybe<Scalars['String']>;
  latitude?: InputMaybe<Scalars['Float']>;
  longitude?: InputMaybe<Scalars['Float']>;
  maxDistanceInputKm?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  rows?: InputMaybe<Scalars['Int']>;
  searchString?: InputMaybe<Scalars['String']>;
};


export type QueryPatronProductsSearchArgs = {
  businessType?: InputMaybe<Scalars['String']>;
  latitude?: InputMaybe<Scalars['Float']>;
  longitude?: InputMaybe<Scalars['Float']>;
  maxDistanceInputKm?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  rows?: InputMaybe<Scalars['Int']>;
  searchString?: InputMaybe<Scalars['String']>;
};


export type QueryPatronQueryPurchaseArgs = {
  orderId: Scalars['ID'];
};


export type QueryPatronQueryPurchasesArgs = {
  page?: InputMaybe<Scalars['Int']>;
  rows?: InputMaybe<Scalars['Int']>;
  status: Scalars['String'];
};


export type QueryPatronSearchLocationArgs = {
  businessType?: InputMaybe<Scalars['String']>;
  latitude?: InputMaybe<Scalars['Float']>;
  longitude?: InputMaybe<Scalars['Float']>;
  maxDistanceInputKm?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  rows?: InputMaybe<Scalars['Int']>;
  searchString?: InputMaybe<Scalars['String']>;
};

export type QuerySettings = {
  __typename?: 'QuerySettings';
  businessTypes?: Maybe<Array<Maybe<BusinessType>>>;
  guide: Scalars['String'];
};


export type QuerySettingsBusinessTypesArgs = {
  page?: InputMaybe<Scalars['Int']>;
  rows?: InputMaybe<Scalars['Int']>;
};

export type QuerySettlementsReturnType = {
  __typename?: 'QuerySettlementsReturnType';
  data?: Maybe<Array<Maybe<Settlement>>>;
  numSettlements?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  pages?: Maybe<Scalars['Int']>;
  totals?: Maybe<SettlementTotalsType>;
};

export type SetBusHoursResponseType = {
  __typename?: 'SetBusHoursResponseType';
  data?: Maybe<BusinessHoursResponseType>;
};

export type SettingsResult = {
  __typename?: 'SettingsResult';
  /** The page number queried. */
  page?: Maybe<Scalars['Int']>;
  /** The list of available appSettings returned as an array of appSettings object. */
  settingsData?: Maybe<Array<Maybe<AppSetting>>>;
  /** The total number of available appSettings */
  total?: Maybe<Scalars['Int']>;
};

export type Settlement = {
  __typename?: 'Settlement';
  amountToPayVendor?: Maybe<Scalars['Float']>;
  business?: Maybe<Business>;
  businessId?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  cutOffDateEnd?: Maybe<Scalars['DateTime']>;
  cutOffDateStart?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  isRefund?: Maybe<Scalars['Boolean']>;
  isUpdateable?: Maybe<Scalars['Boolean']>;
  orderIds?: Maybe<Scalars['String']>;
  orders?: Maybe<Order>;
  status?: Maybe<Scalars['String']>;
  totalConvenienceFee?: Maybe<Scalars['Float']>;
  totalFees?: Maybe<Scalars['Float']>;
  totalGrossAmount?: Maybe<Scalars['Float']>;
  totalServiceFee?: Maybe<Scalars['Float']>;
  totalTransactions?: Maybe<Scalars['Int']>;
  totalVat?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SettlementTotalsType = {
  __typename?: 'SettlementTotalsType';
  amountToPayVendor?: Maybe<Scalars['Float']>;
  totalConvenienceFee?: Maybe<Scalars['Float']>;
  totalFees?: Maybe<Scalars['Float']>;
  totalGrossAmount?: Maybe<Scalars['Float']>;
  totalServiceFee?: Maybe<Scalars['Float']>;
  totalTransactions?: Maybe<Scalars['Int']>;
  totalVat?: Maybe<Scalars['Float']>;
};

export enum SortByEnum {
  CreatedAt = 'createdAt',
  FirstName = 'firstName'
}

export type StripeCheckoutSessionType = {
  __typename?: 'StripeCheckoutSessionType';
  orderId?: Maybe<Scalars['ID']>;
  status: Scalars['String'];
};

export type Subscription = {
  __typename?: 'Subscription';
  approved?: Maybe<User>;
  newConversationMessage?: Maybe<ConversationSubscriptionType>;
  numberIncremented?: Maybe<Scalars['Int']>;
  order?: Maybe<OrderSubscriptionType>;
  ordersChangedPatron?: Maybe<OrderSubscriptionType>;
  product?: Maybe<ProductSubscriptionType>;
};


export type SubscriptionNewConversationMessageArgs = {
  userId: Scalars['Int'];
};


export type SubscriptionOrderArgs = {
  businessId: Scalars['Int'];
};


export type SubscriptionOrdersChangedPatronArgs = {
  patronId: Scalars['Int'];
};

export type Token = {
  __typename?: 'Token';
  accessToken?: Maybe<Scalars['String']>;
  refreshToken?: Maybe<Scalars['String']>;
};

export type TransactionDataTotalType = {
  __typename?: 'TransactionDataTotalType';
  amount?: Maybe<Scalars['Float']>;
  convenienceFee?: Maybe<Scalars['Float']>;
  netAmountToVendorsBank?: Maybe<Scalars['Float']>;
  serviceFee?: Maybe<Scalars['Float']>;
  subTotal?: Maybe<Scalars['Float']>;
  vat?: Maybe<Scalars['Float']>;
};

export type TransactionDataType = {
  __typename?: 'TransactionDataType';
  data?: Maybe<Array<Maybe<Order>>>;
  page?: Maybe<Scalars['Int']>;
  pages?: Maybe<Scalars['Int']>;
  totalOrders?: Maybe<Scalars['Int']>;
  totals?: Maybe<TransactionDataTotalType>;
};

export type TypeBreakdown = {
  __typename?: 'TypeBreakdown';
  count?: Maybe<Scalars['Int']>;
  merchantType?: Maybe<Scalars['String']>;
};

export type UpdateAppSettingInputData = {
  /** The id of the existing appSetting to update. */
  id: Scalars['Int'];
  /** The value of the appSetting. */
  value: Scalars['String'];
};

export type UpdateBusinessType = {
  isDisabled?: InputMaybe<Scalars['Boolean']>;
  value?: InputMaybe<Scalars['String']>;
};

export type UpdateMerchantSettingData = {
  /** ConvenienceFee is the fixed amount that the merchant will pay to the App per transaction. The minimum value is set originally to 10.00. */
  convenienceFee?: InputMaybe<Scalars['Float']>;
  /** When set to true the convenienceFee is added to the total amount that the patron will pay per transaction. When set to false, the convenienceFee will NOT be added to the patron's total payment. */
  convenienceFeeEnabled?: InputMaybe<Scalars['Boolean']>;
  /** ServiceFee is the percentage of the transaction that will be paid to the App. The default value is originally set to 0.05 or 5%. */
  serviceFee?: InputMaybe<Scalars['Float']>;
  /** When set to true the serviceFee is added to the total amount that the patron will pay per transaction. */
  serviceFeeEnabled?: InputMaybe<Scalars['Boolean']>;
  /** The maximum amount per transaction for a merchant. Originally set to 99,999,999.99 */
  transactionLimit?: InputMaybe<Scalars['Float']>;
};

export type UpdateOrderRefund = {
  orderId?: InputMaybe<Scalars['Int']>;
  refundStatus?: InputMaybe<Scalars['String']>;
};

export type UpdatedData = {
  __typename?: 'UpdatedData';
  data?: Maybe<UpdatedFields>;
};

export type UpdatedFields = {
  __typename?: 'UpdatedFields';
  bankAccountData?: Maybe<BankAccount>;
  businessName?: Maybe<Scalars['String']>;
  formattedAddress?: Maybe<Scalars['String']>;
  isOpen?: Maybe<Scalars['Boolean']>;
  isOpenCalc?: Maybe<Scalars['Boolean']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

export type User = {
  __typename?: 'User';
  avatar?: Maybe<Scalars['String']>;
  business?: Maybe<Business>;
  contactNumber?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  fcmToken?: Maybe<Scalars['String']>;
  firebaseUid?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isStaff?: Maybe<Scalars['Boolean']>;
  isStripeFullyOnboarded?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  permissions?: Maybe<Array<Maybe<AdminPermission>>>;
  role?: Maybe<Scalars['String']>;
  signedS3Avatar?: Maybe<Scalars['String']>;
  stripeAccountId?: Maybe<Scalars['String']>;
};

export type UserAuthenticationTokens = {
  __typename?: 'UserAuthenticationTokens';
  accessToken?: Maybe<Scalars['String']>;
  refreshToken?: Maybe<Scalars['String']>;
};

export type UserData = {
  business?: InputMaybe<BusinessData>;
  confirmPassword?: InputMaybe<Scalars['String']>;
  contactNumber?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  fcmToken?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  newPassword?: InputMaybe<Scalars['String']>;
};

export type VariationInput = {
  selected_options?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  variation_id: Scalars['ID'];
};

export type CreateOrderOnBehalfData = {
  businessId: Scalars['ID'];
  firstName?: InputMaybe<Scalars['String']>;
  items: Array<InputMaybe<ItemData>>;
  paymentMethod: Scalars['String'];
  phoneNumber?: InputMaybe<Scalars['String']>;
  specialInstructions?: InputMaybe<Scalars['String']>;
  tipAmount?: InputMaybe<Scalars['Float']>;
};


export const MutateMeDocument = gql`
    mutation MutateMe($data: UserData!, $file: Upload) {
  me {
    update(data: $data, file: $file) {
      avatar
      email
      signedS3Avatar
    }
  }
}
    `;
export type MutateMeMutationFn = Apollo.MutationFunction<MutateMeMutation, MutateMeMutationVariables>;

/**
 * __useMutateMeMutation__
 *
 * To run a mutation, you first call `useMutateMeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMutateMeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mutateMeMutation, { data, loading, error }] = useMutateMeMutation({
 *   variables: {
 *      data: // value for 'data'
 *      file: // value for 'file'
 *   },
 * });
 */
export function useMutateMeMutation(baseOptions?: Apollo.MutationHookOptions<MutateMeMutation, MutateMeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MutateMeMutation, MutateMeMutationVariables>(MutateMeDocument, options);
      }
export type MutateMeMutationHookResult = ReturnType<typeof useMutateMeMutation>;
export type MutateMeMutationResult = Apollo.MutationResult<MutateMeMutation>;
export type MutateMeMutationOptions = Apollo.BaseMutationOptions<MutateMeMutation, MutateMeMutationVariables>;
export const BusinessTypeQueryDocument = gql`
    query BusinessTypeQuery {
  patron {
    businessTypes {
      value
      id
      isDisabled
    }
  }
}
    `;

/**
 * __useBusinessTypeQueryQuery__
 *
 * To run a query within a React component, call `useBusinessTypeQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusinessTypeQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusinessTypeQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useBusinessTypeQueryQuery(baseOptions?: Apollo.QueryHookOptions<BusinessTypeQueryQuery, BusinessTypeQueryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BusinessTypeQueryQuery, BusinessTypeQueryQueryVariables>(BusinessTypeQueryDocument, options);
      }
export function useBusinessTypeQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BusinessTypeQueryQuery, BusinessTypeQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BusinessTypeQueryQuery, BusinessTypeQueryQueryVariables>(BusinessTypeQueryDocument, options);
        }
export type BusinessTypeQueryQueryHookResult = ReturnType<typeof useBusinessTypeQueryQuery>;
export type BusinessTypeQueryLazyQueryHookResult = ReturnType<typeof useBusinessTypeQueryLazyQuery>;
export type BusinessTypeQueryQueryResult = Apollo.QueryResult<BusinessTypeQueryQuery, BusinessTypeQueryQueryVariables>;
export const CreateConversationAboutOrderDocument = gql`
    mutation createConversationAboutOrder($orderId: String!) {
  createConversationAboutOrder(orderId: $orderId) {
    id
    title
    members {
      id
      firstName
      lastName
      signedS3Avatar
    }
    messages {
      id
      senderId
      content
      createdAt
    }
  }
}
    `;
export type CreateConversationAboutOrderMutationFn = Apollo.MutationFunction<CreateConversationAboutOrderMutation, CreateConversationAboutOrderMutationVariables>;

/**
 * __useCreateConversationAboutOrderMutation__
 *
 * To run a mutation, you first call `useCreateConversationAboutOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateConversationAboutOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createConversationAboutOrderMutation, { data, loading, error }] = useCreateConversationAboutOrderMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useCreateConversationAboutOrderMutation(baseOptions?: Apollo.MutationHookOptions<CreateConversationAboutOrderMutation, CreateConversationAboutOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateConversationAboutOrderMutation, CreateConversationAboutOrderMutationVariables>(CreateConversationAboutOrderDocument, options);
      }
export type CreateConversationAboutOrderMutationHookResult = ReturnType<typeof useCreateConversationAboutOrderMutation>;
export type CreateConversationAboutOrderMutationResult = Apollo.MutationResult<CreateConversationAboutOrderMutation>;
export type CreateConversationAboutOrderMutationOptions = Apollo.BaseMutationOptions<CreateConversationAboutOrderMutation, CreateConversationAboutOrderMutationVariables>;
export const GetAllConversationsDocument = gql`
    query getAllConversations($messageLimit: Int) {
  getAllConversations(messageLimit: $messageLimit) {
    id
    title
    createdAt
    updatedAt
    orderId
    businessLogo
    businessName
    members {
      id
      firstName
      lastName
      signedS3Avatar
    }
    memberDetails {
      userId
      lastReadTimestamp
    }
    messages {
      id
      content
      createdAt
      sender {
        id
        firstName
        lastName
      }
    }
  }
}
    `;

/**
 * __useGetAllConversationsQuery__
 *
 * To run a query within a React component, call `useGetAllConversationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllConversationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllConversationsQuery({
 *   variables: {
 *      messageLimit: // value for 'messageLimit'
 *   },
 * });
 */
export function useGetAllConversationsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllConversationsQuery, GetAllConversationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllConversationsQuery, GetAllConversationsQueryVariables>(GetAllConversationsDocument, options);
      }
export function useGetAllConversationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllConversationsQuery, GetAllConversationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllConversationsQuery, GetAllConversationsQueryVariables>(GetAllConversationsDocument, options);
        }
export type GetAllConversationsQueryHookResult = ReturnType<typeof useGetAllConversationsQuery>;
export type GetAllConversationsLazyQueryHookResult = ReturnType<typeof useGetAllConversationsLazyQuery>;
export type GetAllConversationsQueryResult = Apollo.QueryResult<GetAllConversationsQuery, GetAllConversationsQueryVariables>;
export const GetConversationByIdDocument = gql`
    query getConversationById($conversationId: String!) {
  getConversationById(conversationId: $conversationId) {
    id
    title
    createdAt
    updatedAt
    orderId
    members {
      id
      firstName
      lastName
      signedS3Avatar
    }
    memberDetails {
      userId
      lastReadTimestamp
    }
    messages {
      id
      content
      createdAt
      sender {
        id
        firstName
        lastName
      }
    }
  }
}
    `;

/**
 * __useGetConversationByIdQuery__
 *
 * To run a query within a React component, call `useGetConversationByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConversationByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConversationByIdQuery({
 *   variables: {
 *      conversationId: // value for 'conversationId'
 *   },
 * });
 */
export function useGetConversationByIdQuery(baseOptions: Apollo.QueryHookOptions<GetConversationByIdQuery, GetConversationByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetConversationByIdQuery, GetConversationByIdQueryVariables>(GetConversationByIdDocument, options);
      }
export function useGetConversationByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetConversationByIdQuery, GetConversationByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetConversationByIdQuery, GetConversationByIdQueryVariables>(GetConversationByIdDocument, options);
        }
export type GetConversationByIdQueryHookResult = ReturnType<typeof useGetConversationByIdQuery>;
export type GetConversationByIdLazyQueryHookResult = ReturnType<typeof useGetConversationByIdLazyQuery>;
export type GetConversationByIdQueryResult = Apollo.QueryResult<GetConversationByIdQuery, GetConversationByIdQueryVariables>;
export const SearchLocationDocument = gql`
    query SearchLocation($productsSearchBusinessType: String, $productsSearchSearchString: String, $latitude: Float, $longitude: Float, $page: Int, $rows: Int, $maxDistanceInputKm: Int) {
  patron {
    searchLocation(
      businessType: $productsSearchBusinessType
      searchString: $productsSearchSearchString
      latitude: $latitude
      longitude: $longitude
      page: $page
      rows: $rows
      maxDistanceInputKm: $maxDistanceInputKm
    ) {
      total
      page
      perPage
      data {
        businessId
        businessName
        storeDescription
        city
        companyType
        country
        createdAt
        formattedAddress
        isOpen
        isVerified
        latitude
        longitude
        merchantType
        products {
          description
          price
          name
          qrCode
          id
          images {
            filepath
            signedS3Url
          }
          createdAt
          updatedAt
          redirectUrl
          disabledAt
        }
        users {
          id
          lastName
          role
          signedS3Avatar
          email
          firstName
          contactNumber
        }
        qrCode
        redirectUrl
        street
        signedS3UrlLogo
        signedS3UrlBanner
        updatedAt
        zipcode
        isAcceptingOrders
        isManualOpenCloseUpdate
        businessSchedules {
          businessScheduleId
          day
          openingTime
          closingTime
        }
      }
    }
  }
}
    `;

/**
 * __useSearchLocationQuery__
 *
 * To run a query within a React component, call `useSearchLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchLocationQuery({
 *   variables: {
 *      productsSearchBusinessType: // value for 'productsSearchBusinessType'
 *      productsSearchSearchString: // value for 'productsSearchSearchString'
 *      latitude: // value for 'latitude'
 *      longitude: // value for 'longitude'
 *      page: // value for 'page'
 *      rows: // value for 'rows'
 *      maxDistanceInputKm: // value for 'maxDistanceInputKm'
 *   },
 * });
 */
export function useSearchLocationQuery(baseOptions?: Apollo.QueryHookOptions<SearchLocationQuery, SearchLocationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchLocationQuery, SearchLocationQueryVariables>(SearchLocationDocument, options);
      }
export function useSearchLocationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchLocationQuery, SearchLocationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchLocationQuery, SearchLocationQueryVariables>(SearchLocationDocument, options);
        }
export type SearchLocationQueryHookResult = ReturnType<typeof useSearchLocationQuery>;
export type SearchLocationLazyQueryHookResult = ReturnType<typeof useSearchLocationLazyQuery>;
export type SearchLocationQueryResult = Apollo.QueryResult<SearchLocationQuery, SearchLocationQueryVariables>;
export const LoginDocument = gql`
    mutation login($email: String!, $password: String!, $isKeepLoggedIn: Boolean, $isPatron: Boolean, $isVerify: Boolean) {
  login(
    email: $email
    password: $password
    isKeepLoggedIn: $isKeepLoggedIn
    isPatron: $isPatron
    isVerify: $isVerify
  ) {
    tokens {
      accessToken
      refreshToken
    }
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      isKeepLoggedIn: // value for 'isKeepLoggedIn'
 *      isPatron: // value for 'isPatron'
 *      isVerify: // value for 'isVerify'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const MarkConversationReadDocument = gql`
    mutation markConversationRead($conversationId: String!) {
  markConversationRead(conversationId: $conversationId)
}
    `;
export type MarkConversationReadMutationFn = Apollo.MutationFunction<MarkConversationReadMutation, MarkConversationReadMutationVariables>;

/**
 * __useMarkConversationReadMutation__
 *
 * To run a mutation, you first call `useMarkConversationReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkConversationReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markConversationReadMutation, { data, loading, error }] = useMarkConversationReadMutation({
 *   variables: {
 *      conversationId: // value for 'conversationId'
 *   },
 * });
 */
export function useMarkConversationReadMutation(baseOptions?: Apollo.MutationHookOptions<MarkConversationReadMutation, MarkConversationReadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarkConversationReadMutation, MarkConversationReadMutationVariables>(MarkConversationReadDocument, options);
      }
export type MarkConversationReadMutationHookResult = ReturnType<typeof useMarkConversationReadMutation>;
export type MarkConversationReadMutationResult = Apollo.MutationResult<MarkConversationReadMutation>;
export type MarkConversationReadMutationOptions = Apollo.BaseMutationOptions<MarkConversationReadMutation, MarkConversationReadMutationVariables>;
export const MeDocument = gql`
    query Me {
  me {
    id
    firstName
    lastName
    email
    role
    contactNumber
    avatar
    signedS3Avatar
  }
}
    `;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const MerchantProductsDocument = gql`
    query MerchantProducts($businessId: Int!, $page: Int, $rows: Int, $searchString: String) {
  patron {
    merchantProducts(
      businessId: $businessId
      page: $page
      rows: $rows
      searchString: $searchString
    ) {
      page
      perPage
      products {
        description
        id
        images {
          id
          signedS3Url
          filepath
          createdAt
          updatedAt
          productId
        }
        business {
          businessId
          businessName
          storeDescription
          companyType
          products {
            id
          }
          isAcceptingOrders
          formattedAddress
          signedS3UrlLogo
          signedS3UrlBanner
          isManualOpenCloseUpdate
          businessSchedules {
            businessScheduleId
            day
            openingTime
            closingTime
          }
        }
        name
        price
        qrCode
        redirectUrl
        createdAt
        disabledAt
        updatedAt
        stock
        parLevel
        startDateTime
        endDateTime
        stock
        category
        variations {
          heading
          id
          maxChoices
          minChoices
          required
          options {
            choiceName
            extraCost
            id
          }
        }
      }
      total
    }
  }
}
    `;

/**
 * __useMerchantProductsQuery__
 *
 * To run a query within a React component, call `useMerchantProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMerchantProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMerchantProductsQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      page: // value for 'page'
 *      rows: // value for 'rows'
 *      searchString: // value for 'searchString'
 *   },
 * });
 */
export function useMerchantProductsQuery(baseOptions: Apollo.QueryHookOptions<MerchantProductsQuery, MerchantProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MerchantProductsQuery, MerchantProductsQueryVariables>(MerchantProductsDocument, options);
      }
export function useMerchantProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MerchantProductsQuery, MerchantProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MerchantProductsQuery, MerchantProductsQueryVariables>(MerchantProductsDocument, options);
        }
export type MerchantProductsQueryHookResult = ReturnType<typeof useMerchantProductsQuery>;
export type MerchantProductsLazyQueryHookResult = ReturnType<typeof useMerchantProductsLazyQuery>;
export type MerchantProductsQueryResult = Apollo.QueryResult<MerchantProductsQuery, MerchantProductsQueryVariables>;
export const UserNotificationsDocument = gql`
    query UserNotifications($perPage: Int, $page: Int) {
  notifications {
    userNotifications(perPage: $perPage, page: $page) {
      data {
        body
        createdAt
        id
        isRead
        sentAt
        title
        type
        uid
      }
      itemCount
      page
      pageCount
      perPage
      unreadCount
    }
  }
}
    `;

/**
 * __useUserNotificationsQuery__
 *
 * To run a query within a React component, call `useUserNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserNotificationsQuery({
 *   variables: {
 *      perPage: // value for 'perPage'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useUserNotificationsQuery(baseOptions?: Apollo.QueryHookOptions<UserNotificationsQuery, UserNotificationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserNotificationsQuery, UserNotificationsQueryVariables>(UserNotificationsDocument, options);
      }
export function useUserNotificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserNotificationsQuery, UserNotificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserNotificationsQuery, UserNotificationsQueryVariables>(UserNotificationsDocument, options);
        }
export type UserNotificationsQueryHookResult = ReturnType<typeof useUserNotificationsQuery>;
export type UserNotificationsLazyQueryHookResult = ReturnType<typeof useUserNotificationsLazyQuery>;
export type UserNotificationsQueryResult = Apollo.QueryResult<UserNotificationsQuery, UserNotificationsQueryVariables>;
export const OrderTotalDocument = gql`
    query OrderTotal($data: OrderData!) {
  patron {
    orderTotal(data: $data) {
      computedFee
      convenienceFee
      serviceFee
      total
      type
      vat
      orderSummary {
        subTotal
        orderedItems {
          extraCost
          orderedQty
          product {
            id
          }
        }
      }
    }
  }
}
    `;

/**
 * __useOrderTotalQuery__
 *
 * To run a query within a React component, call `useOrderTotalQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderTotalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderTotalQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useOrderTotalQuery(baseOptions: Apollo.QueryHookOptions<OrderTotalQuery, OrderTotalQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrderTotalQuery, OrderTotalQueryVariables>(OrderTotalDocument, options);
      }
export function useOrderTotalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrderTotalQuery, OrderTotalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrderTotalQuery, OrderTotalQueryVariables>(OrderTotalDocument, options);
        }
export type OrderTotalQueryHookResult = ReturnType<typeof useOrderTotalQuery>;
export type OrderTotalLazyQueryHookResult = ReturnType<typeof useOrderTotalLazyQuery>;
export type OrderTotalQueryResult = Apollo.QueryResult<OrderTotalQuery, OrderTotalQueryVariables>;
export const ProductPurchaseDocument = gql`
    mutation ProductPurchase($data: PurchaseData!) {
  patron {
    purchase(data: $data) {
      url
    }
  }
}
    `;
export type ProductPurchaseMutationFn = Apollo.MutationFunction<ProductPurchaseMutation, ProductPurchaseMutationVariables>;

/**
 * __useProductPurchaseMutation__
 *
 * To run a mutation, you first call `useProductPurchaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProductPurchaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [productPurchaseMutation, { data, loading, error }] = useProductPurchaseMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useProductPurchaseMutation(baseOptions?: Apollo.MutationHookOptions<ProductPurchaseMutation, ProductPurchaseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProductPurchaseMutation, ProductPurchaseMutationVariables>(ProductPurchaseDocument, options);
      }
export type ProductPurchaseMutationHookResult = ReturnType<typeof useProductPurchaseMutation>;
export type ProductPurchaseMutationResult = Apollo.MutationResult<ProductPurchaseMutation>;
export type ProductPurchaseMutationOptions = Apollo.BaseMutationOptions<ProductPurchaseMutation, ProductPurchaseMutationVariables>;
export const ProductsSearchDocument = gql`
    query ProductsSearch($productsSearchBusinessType: String!, $productsSearchSearchString: String, $productsSearchLatitude: Float, $productsSearchLongitude: Float) {
  patron {
    productsSearch(
      businessType: $productsSearchBusinessType
      searchString: $productsSearchSearchString
      latitude: $productsSearchLatitude
      longitude: $productsSearchLongitude
    ) {
      data {
        businessId
        businessName
        storeDescription
        city
        companyType
        country
        createdAt
        formattedAddress
        isOpen
        isVerified
        latitude
        longitude
        merchantType
        products {
          description
          price
          name
          qrCode
          id
          images {
            filepath
            signedS3Url
          }
          createdAt
          updatedAt
          redirectUrl
          disabledAt
        }
        users {
          id
          lastName
          role
          signedS3Avatar
          email
          firstName
          contactNumber
        }
        qrCode
        redirectUrl
        street
        signedS3UrlLogo
        signedS3UrlBanner
        updatedAt
        zipcode
        isAcceptingOrders
        isManualOpenCloseUpdate
        businessSchedules {
          businessScheduleId
          day
          openingTime
          closingTime
        }
      }
    }
  }
}
    `;

/**
 * __useProductsSearchQuery__
 *
 * To run a query within a React component, call `useProductsSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductsSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductsSearchQuery({
 *   variables: {
 *      productsSearchBusinessType: // value for 'productsSearchBusinessType'
 *      productsSearchSearchString: // value for 'productsSearchSearchString'
 *      productsSearchLatitude: // value for 'productsSearchLatitude'
 *      productsSearchLongitude: // value for 'productsSearchLongitude'
 *   },
 * });
 */
export function useProductsSearchQuery(baseOptions: Apollo.QueryHookOptions<ProductsSearchQuery, ProductsSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductsSearchQuery, ProductsSearchQueryVariables>(ProductsSearchDocument, options);
      }
export function useProductsSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductsSearchQuery, ProductsSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductsSearchQuery, ProductsSearchQueryVariables>(ProductsSearchDocument, options);
        }
export type ProductsSearchQueryHookResult = ReturnType<typeof useProductsSearchQuery>;
export type ProductsSearchLazyQueryHookResult = ReturnType<typeof useProductsSearchLazyQuery>;
export type ProductsSearchQueryResult = Apollo.QueryResult<ProductsSearchQuery, ProductsSearchQueryVariables>;
export const PublicMerchantDocument = gql`
    query PublicMerchant($businessId: ID!) {
  publicMerchant(businessId: $businessId) {
    avatar
    contactNumber
    email
    firstName
    id
    lastName
    role
    business {
      businessName
      storeDescription
      isAcceptingOrders
      isOpen
      businessId
      latitude
      longitude
      merchantType
      products {
        id
      }
      signedS3UrlLogo
      signedS3UrlBanner
      formattedAddress
      openingTime
      closingTime
      isManualOpenCloseUpdate
      businessSchedules {
        businessScheduleId
        day
        openingTime
        closingTime
      }
    }
  }
}
    `;

/**
 * __usePublicMerchantQuery__
 *
 * To run a query within a React component, call `usePublicMerchantQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicMerchantQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicMerchantQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *   },
 * });
 */
export function usePublicMerchantQuery(baseOptions: Apollo.QueryHookOptions<PublicMerchantQuery, PublicMerchantQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PublicMerchantQuery, PublicMerchantQueryVariables>(PublicMerchantDocument, options);
      }
export function usePublicMerchantLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PublicMerchantQuery, PublicMerchantQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PublicMerchantQuery, PublicMerchantQueryVariables>(PublicMerchantDocument, options);
        }
export type PublicMerchantQueryHookResult = ReturnType<typeof usePublicMerchantQuery>;
export type PublicMerchantLazyQueryHookResult = ReturnType<typeof usePublicMerchantLazyQuery>;
export type PublicMerchantQueryResult = Apollo.QueryResult<PublicMerchantQuery, PublicMerchantQueryVariables>;
export const PublicMerchantProductsDocument = gql`
    query PublicMerchantProducts($businessId: Int!, $page: Int, $rows: Int, $searchString: String) {
  publicMerchantProducts(
    businessId: $businessId
    page: $page
    rows: $rows
    searchString: $searchString
  ) {
    page
    perPage
    total
    products {
      description
      id
      images {
        id
        signedS3Url
        filepath
        createdAt
        updatedAt
        productId
      }
      business {
        businessId
        businessName
        storeDescription
        companyType
        products {
          id
        }
        isAcceptingOrders
        formattedAddress
        signedS3UrlLogo
        signedS3UrlBanner
        isManualOpenCloseUpdate
        businessSchedules {
          businessScheduleId
          day
          openingTime
          closingTime
        }
      }
      name
      price
      qrCode
      redirectUrl
      createdAt
      disabledAt
      updatedAt
      stock
      parLevel
      startDateTime
      endDateTime
      variations {
        heading
        id
        maxChoices
        minChoices
        required
        options {
          choiceName
          extraCost
          id
        }
      }
    }
  }
}
    `;

/**
 * __usePublicMerchantProductsQuery__
 *
 * To run a query within a React component, call `usePublicMerchantProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicMerchantProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicMerchantProductsQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      page: // value for 'page'
 *      rows: // value for 'rows'
 *      searchString: // value for 'searchString'
 *   },
 * });
 */
export function usePublicMerchantProductsQuery(baseOptions: Apollo.QueryHookOptions<PublicMerchantProductsQuery, PublicMerchantProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PublicMerchantProductsQuery, PublicMerchantProductsQueryVariables>(PublicMerchantProductsDocument, options);
      }
export function usePublicMerchantProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PublicMerchantProductsQuery, PublicMerchantProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PublicMerchantProductsQuery, PublicMerchantProductsQueryVariables>(PublicMerchantProductsDocument, options);
        }
export type PublicMerchantProductsQueryHookResult = ReturnType<typeof usePublicMerchantProductsQuery>;
export type PublicMerchantProductsLazyQueryHookResult = ReturnType<typeof usePublicMerchantProductsLazyQuery>;
export type PublicMerchantProductsQueryResult = Apollo.QueryResult<PublicMerchantProductsQuery, PublicMerchantProductsQueryVariables>;
export const PurchaseHistoryDocument = gql`
    query PurchaseHistory($status: String!, $rows: Int, $page: Int) {
  patron {
    queryPurchases(status: $status, rows: $rows, page: $page) {
      data {
        amount
        businessId
        convenienceFee
        createdAt
        customerEmail
        customerPhoneNumber
        firstName
        id
        isClaimed
        lastName
        orderItems {
          description
          id
          name
          price
          productId
          quantity
          variations {
            id
            variation_id
            selected_options
          }
        }
        specialInstructions
        cashPaymentConfirmed
        isSeen
        orderStatus
        status
        paymentMethod
        referenceId
        subTotal
        serviceFee
        updatedAt
        business {
          businessName
          businessId
          formattedAddress
          latitude
          longitude
          signedS3UrlLogo
          products {
            id
          }
        }
        details
      }
      page
      total
      pages
    }
  }
}
    `;

/**
 * __usePurchaseHistoryQuery__
 *
 * To run a query within a React component, call `usePurchaseHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `usePurchaseHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePurchaseHistoryQuery({
 *   variables: {
 *      status: // value for 'status'
 *      rows: // value for 'rows'
 *      page: // value for 'page'
 *   },
 * });
 */
export function usePurchaseHistoryQuery(baseOptions: Apollo.QueryHookOptions<PurchaseHistoryQuery, PurchaseHistoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PurchaseHistoryQuery, PurchaseHistoryQueryVariables>(PurchaseHistoryDocument, options);
      }
export function usePurchaseHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PurchaseHistoryQuery, PurchaseHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PurchaseHistoryQuery, PurchaseHistoryQueryVariables>(PurchaseHistoryDocument, options);
        }
export type PurchaseHistoryQueryHookResult = ReturnType<typeof usePurchaseHistoryQuery>;
export type PurchaseHistoryLazyQueryHookResult = ReturnType<typeof usePurchaseHistoryLazyQuery>;
export type PurchaseHistoryQueryResult = Apollo.QueryResult<PurchaseHistoryQuery, PurchaseHistoryQueryVariables>;
export const PurchaseHistorySingleDocument = gql`
    query PurchaseHistorySingle($orderId: ID!) {
  patron {
    queryPurchase(orderId: $orderId) {
      amount
      business {
        businessName
        businessId
        products {
          id
        }
      }
      businessId
      createdAt
      id
      isClaimed
      orderItems {
        id
        description
        images {
          id
          filepath
        }
        price
        name
        productId
        quantity
        variations {
          id
          variation_id
          selected_options
        }
      }
      orderStatus
      paymentMethod
      referenceId
      orderStatus
      status
      customerEmail
      customerPhoneNumber
      specialInstructions
      cashPaymentConfirmed
      isSeen
    }
  }
}
    `;

/**
 * __usePurchaseHistorySingleQuery__
 *
 * To run a query within a React component, call `usePurchaseHistorySingleQuery` and pass it any options that fit your needs.
 * When your component renders, `usePurchaseHistorySingleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePurchaseHistorySingleQuery({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function usePurchaseHistorySingleQuery(baseOptions: Apollo.QueryHookOptions<PurchaseHistorySingleQuery, PurchaseHistorySingleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PurchaseHistorySingleQuery, PurchaseHistorySingleQueryVariables>(PurchaseHistorySingleDocument, options);
      }
export function usePurchaseHistorySingleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PurchaseHistorySingleQuery, PurchaseHistorySingleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PurchaseHistorySingleQuery, PurchaseHistorySingleQueryVariables>(PurchaseHistorySingleDocument, options);
        }
export type PurchaseHistorySingleQueryHookResult = ReturnType<typeof usePurchaseHistorySingleQuery>;
export type PurchaseHistorySingleLazyQueryHookResult = ReturnType<typeof usePurchaseHistorySingleLazyQuery>;
export type PurchaseHistorySingleQueryResult = Apollo.QueryResult<PurchaseHistorySingleQuery, PurchaseHistorySingleQueryVariables>;
export const MaintenanceModeDocument = gql`
    query MaintenanceMode {
  isMaintenanceMode
}
    `;

/**
 * __useMaintenanceModeQuery__
 *
 * To run a query within a React component, call `useMaintenanceModeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMaintenanceModeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMaintenanceModeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMaintenanceModeQuery(baseOptions?: Apollo.QueryHookOptions<MaintenanceModeQuery, MaintenanceModeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MaintenanceModeQuery, MaintenanceModeQueryVariables>(MaintenanceModeDocument, options);
      }
export function useMaintenanceModeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MaintenanceModeQuery, MaintenanceModeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MaintenanceModeQuery, MaintenanceModeQueryVariables>(MaintenanceModeDocument, options);
        }
export type MaintenanceModeQueryHookResult = ReturnType<typeof useMaintenanceModeQuery>;
export type MaintenanceModeLazyQueryHookResult = ReturnType<typeof useMaintenanceModeLazyQuery>;
export type MaintenanceModeQueryResult = Apollo.QueryResult<MaintenanceModeQuery, MaintenanceModeQueryVariables>;
export const PatronSingleOrderDocument = gql`
    query PatronSingleOrder($orderReferenceId: String!) {
  patron {
    order(orderReferenceId: $orderReferenceId) {
      amount
      id
      subTotal
      serviceFee
      vat
      referenceId
      createdAt
      updatedAt
      specialInstructions
      cashPaymentConfirmed
      isSeen
      patron {
        firstName
        lastName
      }
      orderItems {
        price
        description
        id
        quantity
        productId
        product {
          id
          images {
            id
            signedS3Url
          }
        }
        name
        variations {
          id
          options {
            choiceName
            extraCost
            id
          }
          required
          maxChoices
          minChoices
          heading
        }
      }
    }
  }
}
    `;

/**
 * __usePatronSingleOrderQuery__
 *
 * To run a query within a React component, call `usePatronSingleOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatronSingleOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatronSingleOrderQuery({
 *   variables: {
 *      orderReferenceId: // value for 'orderReferenceId'
 *   },
 * });
 */
export function usePatronSingleOrderQuery(baseOptions: Apollo.QueryHookOptions<PatronSingleOrderQuery, PatronSingleOrderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PatronSingleOrderQuery, PatronSingleOrderQueryVariables>(PatronSingleOrderDocument, options);
      }
export function usePatronSingleOrderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PatronSingleOrderQuery, PatronSingleOrderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PatronSingleOrderQuery, PatronSingleOrderQueryVariables>(PatronSingleOrderDocument, options);
        }
export type PatronSingleOrderQueryHookResult = ReturnType<typeof usePatronSingleOrderQuery>;
export type PatronSingleOrderLazyQueryHookResult = ReturnType<typeof usePatronSingleOrderLazyQuery>;
export type PatronSingleOrderQueryResult = Apollo.QueryResult<PatronSingleOrderQuery, PatronSingleOrderQueryVariables>;
export const ReadAllNotificationsDocument = gql`
    mutation ReadAllNotifications($perPage: Int, $page: Int) {
  notifications {
    readAllNotifications {
      userNotifications(perPage: $perPage, page: $page) {
        itemCount
      }
    }
  }
}
    `;
export type ReadAllNotificationsMutationFn = Apollo.MutationFunction<ReadAllNotificationsMutation, ReadAllNotificationsMutationVariables>;

/**
 * __useReadAllNotificationsMutation__
 *
 * To run a mutation, you first call `useReadAllNotificationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReadAllNotificationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [readAllNotificationsMutation, { data, loading, error }] = useReadAllNotificationsMutation({
 *   variables: {
 *      perPage: // value for 'perPage'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useReadAllNotificationsMutation(baseOptions?: Apollo.MutationHookOptions<ReadAllNotificationsMutation, ReadAllNotificationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReadAllNotificationsMutation, ReadAllNotificationsMutationVariables>(ReadAllNotificationsDocument, options);
      }
export type ReadAllNotificationsMutationHookResult = ReturnType<typeof useReadAllNotificationsMutation>;
export type ReadAllNotificationsMutationResult = Apollo.MutationResult<ReadAllNotificationsMutation>;
export type ReadAllNotificationsMutationOptions = Apollo.BaseMutationOptions<ReadAllNotificationsMutation, ReadAllNotificationsMutationVariables>;
export const ReadNotificationDocument = gql`
    mutation ReadNotification($notificationId: Int) {
  notifications {
    readNotification(notificationId: $notificationId) {
      isRead
      id
    }
  }
}
    `;
export type ReadNotificationMutationFn = Apollo.MutationFunction<ReadNotificationMutation, ReadNotificationMutationVariables>;

/**
 * __useReadNotificationMutation__
 *
 * To run a mutation, you first call `useReadNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReadNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [readNotificationMutation, { data, loading, error }] = useReadNotificationMutation({
 *   variables: {
 *      notificationId: // value for 'notificationId'
 *   },
 * });
 */
export function useReadNotificationMutation(baseOptions?: Apollo.MutationHookOptions<ReadNotificationMutation, ReadNotificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReadNotificationMutation, ReadNotificationMutationVariables>(ReadNotificationDocument, options);
      }
export type ReadNotificationMutationHookResult = ReturnType<typeof useReadNotificationMutation>;
export type ReadNotificationMutationResult = Apollo.MutationResult<ReadNotificationMutation>;
export type ReadNotificationMutationOptions = Apollo.BaseMutationOptions<ReadNotificationMutation, ReadNotificationMutationVariables>;
export const RefreshTokenDocument = gql`
    mutation RefreshToken($refreshToken: String) {
  refreshToken(refreshToken: $refreshToken) {
    accessToken
    refreshToken
  }
}
    `;
export type RefreshTokenMutationFn = Apollo.MutationFunction<RefreshTokenMutation, RefreshTokenMutationVariables>;

/**
 * __useRefreshTokenMutation__
 *
 * To run a mutation, you first call `useRefreshTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshTokenMutation, { data, loading, error }] = useRefreshTokenMutation({
 *   variables: {
 *      refreshToken: // value for 'refreshToken'
 *   },
 * });
 */
export function useRefreshTokenMutation(baseOptions?: Apollo.MutationHookOptions<RefreshTokenMutation, RefreshTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RefreshTokenMutation, RefreshTokenMutationVariables>(RefreshTokenDocument, options);
      }
export type RefreshTokenMutationHookResult = ReturnType<typeof useRefreshTokenMutation>;
export type RefreshTokenMutationResult = Apollo.MutationResult<RefreshTokenMutation>;
export type RefreshTokenMutationOptions = Apollo.BaseMutationOptions<RefreshTokenMutation, RefreshTokenMutationVariables>;
export const RegisterDocument = gql`
    mutation Register($email: String!, $firstName: String!, $lastName: String!, $contactNumber: String!, $userType: String!, $firebaseUid: String!) {
  register(
    email: $email
    firstName: $firstName
    lastName: $lastName
    contactNumber: $contactNumber
    userType: $userType
    firebaseUid: $firebaseUid
  ) {
    id
    firstName
    lastName
    email
    role
    contactNumber
    avatar
  }
}
    `;
export type RegisterMutationFn = Apollo.MutationFunction<RegisterMutation, RegisterMutationVariables>;

/**
 * __useRegisterMutation__
 *
 * To run a mutation, you first call `useRegisterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerMutation, { data, loading, error }] = useRegisterMutation({
 *   variables: {
 *      email: // value for 'email'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      contactNumber: // value for 'contactNumber'
 *      userType: // value for 'userType'
 *      firebaseUid: // value for 'firebaseUid'
 *   },
 * });
 */
export function useRegisterMutation(baseOptions?: Apollo.MutationHookOptions<RegisterMutation, RegisterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterMutation, RegisterMutationVariables>(RegisterDocument, options);
      }
export type RegisterMutationHookResult = ReturnType<typeof useRegisterMutation>;
export type RegisterMutationResult = Apollo.MutationResult<RegisterMutation>;
export type RegisterMutationOptions = Apollo.BaseMutationOptions<RegisterMutation, RegisterMutationVariables>;
export const RequestEmailOtpDocument = gql`
    mutation RequestEmailOtp($email: String!) {
  generateAndSendEmailOtp(email: $email)
}
    `;
export type RequestEmailOtpMutationFn = Apollo.MutationFunction<RequestEmailOtpMutation, RequestEmailOtpMutationVariables>;

/**
 * __useRequestEmailOtpMutation__
 *
 * To run a mutation, you first call `useRequestEmailOtpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestEmailOtpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestEmailOtpMutation, { data, loading, error }] = useRequestEmailOtpMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useRequestEmailOtpMutation(baseOptions?: Apollo.MutationHookOptions<RequestEmailOtpMutation, RequestEmailOtpMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RequestEmailOtpMutation, RequestEmailOtpMutationVariables>(RequestEmailOtpDocument, options);
      }
export type RequestEmailOtpMutationHookResult = ReturnType<typeof useRequestEmailOtpMutation>;
export type RequestEmailOtpMutationResult = Apollo.MutationResult<RequestEmailOtpMutation>;
export type RequestEmailOtpMutationOptions = Apollo.BaseMutationOptions<RequestEmailOtpMutation, RequestEmailOtpMutationVariables>;
export const RequestRefundDocument = gql`
    mutation RequestRefund($productIds: [String], $orderId: ID!, $reason: String) {
  patron {
    requestRefund(productIds: $productIds, orderId: $orderId, reason: $reason) {
      status
    }
  }
}
    `;
export type RequestRefundMutationFn = Apollo.MutationFunction<RequestRefundMutation, RequestRefundMutationVariables>;

/**
 * __useRequestRefundMutation__
 *
 * To run a mutation, you first call `useRequestRefundMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestRefundMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestRefundMutation, { data, loading, error }] = useRequestRefundMutation({
 *   variables: {
 *      productIds: // value for 'productIds'
 *      orderId: // value for 'orderId'
 *      reason: // value for 'reason'
 *   },
 * });
 */
export function useRequestRefundMutation(baseOptions?: Apollo.MutationHookOptions<RequestRefundMutation, RequestRefundMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RequestRefundMutation, RequestRefundMutationVariables>(RequestRefundDocument, options);
      }
export type RequestRefundMutationHookResult = ReturnType<typeof useRequestRefundMutation>;
export type RequestRefundMutationResult = Apollo.MutationResult<RequestRefundMutation>;
export type RequestRefundMutationOptions = Apollo.BaseMutationOptions<RequestRefundMutation, RequestRefundMutationVariables>;
export const ResolveBuskingPayDocument = gql`
    mutation ResolveBuskingPay($data: PurchaseData!) {
  patron {
    resolveCashPurchase(data: $data) {
      referenceId
      amount
      businessId
    }
  }
}
    `;
export type ResolveBuskingPayMutationFn = Apollo.MutationFunction<ResolveBuskingPayMutation, ResolveBuskingPayMutationVariables>;

/**
 * __useResolveBuskingPayMutation__
 *
 * To run a mutation, you first call `useResolveBuskingPayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResolveBuskingPayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resolveBuskingPayMutation, { data, loading, error }] = useResolveBuskingPayMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useResolveBuskingPayMutation(baseOptions?: Apollo.MutationHookOptions<ResolveBuskingPayMutation, ResolveBuskingPayMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResolveBuskingPayMutation, ResolveBuskingPayMutationVariables>(ResolveBuskingPayDocument, options);
      }
export type ResolveBuskingPayMutationHookResult = ReturnType<typeof useResolveBuskingPayMutation>;
export type ResolveBuskingPayMutationResult = Apollo.MutationResult<ResolveBuskingPayMutation>;
export type ResolveBuskingPayMutationOptions = Apollo.BaseMutationOptions<ResolveBuskingPayMutation, ResolveBuskingPayMutationVariables>;
export const ResolveCashPurchaseDocument = gql`
    mutation ResolveCashPurchase($data: PurchaseData!) {
  patron {
    resolveCashPurchase(data: $data) {
      amount
      business {
        businessId
        businessName
        companyType
        createdAt
        formattedAddress
        isOpen
        isVerified
        latitude
        longitude
        merchantType
        updatedAt
        products {
          id
        }
      }
      createdAt
      customerEmail
      customerPhoneNumber
      firstName
      id
      isClaimed
      lastName
      orderItems {
        description
        id
        name
        price
        product {
          name
          price
          description
          id
          updatedAt
          createdAt
          disabledAt
        }
        productId
        quantity
        images {
          filepath
          id
        }
      }
      paymentMethod
      updatedAt
      vat
      subTotal
      serviceFee
      status
      referenceId
      specialInstructions
      cashPaymentConfirmed
      isSeen
    }
  }
}
    `;
export type ResolveCashPurchaseMutationFn = Apollo.MutationFunction<ResolveCashPurchaseMutation, ResolveCashPurchaseMutationVariables>;

/**
 * __useResolveCashPurchaseMutation__
 *
 * To run a mutation, you first call `useResolveCashPurchaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResolveCashPurchaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resolveCashPurchaseMutation, { data, loading, error }] = useResolveCashPurchaseMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useResolveCashPurchaseMutation(baseOptions?: Apollo.MutationHookOptions<ResolveCashPurchaseMutation, ResolveCashPurchaseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResolveCashPurchaseMutation, ResolveCashPurchaseMutationVariables>(ResolveCashPurchaseDocument, options);
      }
export type ResolveCashPurchaseMutationHookResult = ReturnType<typeof useResolveCashPurchaseMutation>;
export type ResolveCashPurchaseMutationResult = Apollo.MutationResult<ResolveCashPurchaseMutation>;
export type ResolveCashPurchaseMutationOptions = Apollo.BaseMutationOptions<ResolveCashPurchaseMutation, ResolveCashPurchaseMutationVariables>;
export const RetrieveStripeCheckoutSessionDocument = gql`
    mutation retrieveStripeCheckoutSession($sessionId: String!) {
  retrieveStripeCheckoutSession(sessionId: $sessionId) {
    status
    orderId
  }
}
    `;
export type RetrieveStripeCheckoutSessionMutationFn = Apollo.MutationFunction<RetrieveStripeCheckoutSessionMutation, RetrieveStripeCheckoutSessionMutationVariables>;

/**
 * __useRetrieveStripeCheckoutSessionMutation__
 *
 * To run a mutation, you first call `useRetrieveStripeCheckoutSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRetrieveStripeCheckoutSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [retrieveStripeCheckoutSessionMutation, { data, loading, error }] = useRetrieveStripeCheckoutSessionMutation({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *   },
 * });
 */
export function useRetrieveStripeCheckoutSessionMutation(baseOptions?: Apollo.MutationHookOptions<RetrieveStripeCheckoutSessionMutation, RetrieveStripeCheckoutSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RetrieveStripeCheckoutSessionMutation, RetrieveStripeCheckoutSessionMutationVariables>(RetrieveStripeCheckoutSessionDocument, options);
      }
export type RetrieveStripeCheckoutSessionMutationHookResult = ReturnType<typeof useRetrieveStripeCheckoutSessionMutation>;
export type RetrieveStripeCheckoutSessionMutationResult = Apollo.MutationResult<RetrieveStripeCheckoutSessionMutation>;
export type RetrieveStripeCheckoutSessionMutationOptions = Apollo.BaseMutationOptions<RetrieveStripeCheckoutSessionMutation, RetrieveStripeCheckoutSessionMutationVariables>;
export const SendMessageDocument = gql`
    mutation SendMessage($conversationId: String!, $content: String!) {
  sendMessage(conversationId: $conversationId, content: $content) {
    id
    content
    createdAt
    senderId
  }
}
    `;
export type SendMessageMutationFn = Apollo.MutationFunction<SendMessageMutation, SendMessageMutationVariables>;

/**
 * __useSendMessageMutation__
 *
 * To run a mutation, you first call `useSendMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendMessageMutation, { data, loading, error }] = useSendMessageMutation({
 *   variables: {
 *      conversationId: // value for 'conversationId'
 *      content: // value for 'content'
 *   },
 * });
 */
export function useSendMessageMutation(baseOptions?: Apollo.MutationHookOptions<SendMessageMutation, SendMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendMessageMutation, SendMessageMutationVariables>(SendMessageDocument, options);
      }
export type SendMessageMutationHookResult = ReturnType<typeof useSendMessageMutation>;
export type SendMessageMutationResult = Apollo.MutationResult<SendMessageMutation>;
export type SendMessageMutationOptions = Apollo.BaseMutationOptions<SendMessageMutation, SendMessageMutationVariables>;
export const WatchNewConversationMessageDocument = gql`
    subscription watchNewConversationMessage($userId: Int!) {
  newConversationMessage(userId: $userId) {
    newMessageDetails {
      members
      conversationId
      message {
        id
        content
        senderId
        conversationId
        createdAt
        sender {
          id
          firstName
          lastName
        }
      }
    }
  }
}
    `;

/**
 * __useWatchNewConversationMessageSubscription__
 *
 * To run a query within a React component, call `useWatchNewConversationMessageSubscription` and pass it any options that fit your needs.
 * When your component renders, `useWatchNewConversationMessageSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWatchNewConversationMessageSubscription({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useWatchNewConversationMessageSubscription(baseOptions: Apollo.SubscriptionHookOptions<WatchNewConversationMessageSubscription, WatchNewConversationMessageSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<WatchNewConversationMessageSubscription, WatchNewConversationMessageSubscriptionVariables>(WatchNewConversationMessageDocument, options);
      }
export type WatchNewConversationMessageSubscriptionHookResult = ReturnType<typeof useWatchNewConversationMessageSubscription>;
export type WatchNewConversationMessageSubscriptionResult = Apollo.SubscriptionResult<WatchNewConversationMessageSubscription>;
export const VendorSearchQueryDocument = gql`
    query VendorSearchQuery($page: Int, $rows: Int, $businessType: String) {
  patron {
    merchants(page: $page, rows: $rows, businessType: $businessType) {
      total
      page
      perPage
      merchants {
        formattedAddress
        merchantType
        longitude
        latitude
        products {
          id
          images {
            filepath
          }
          name
          price
        }
        businessId
      }
    }
  }
}
    `;

/**
 * __useVendorSearchQueryQuery__
 *
 * To run a query within a React component, call `useVendorSearchQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useVendorSearchQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVendorSearchQueryQuery({
 *   variables: {
 *      page: // value for 'page'
 *      rows: // value for 'rows'
 *      businessType: // value for 'businessType'
 *   },
 * });
 */
export function useVendorSearchQueryQuery(baseOptions?: Apollo.QueryHookOptions<VendorSearchQueryQuery, VendorSearchQueryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VendorSearchQueryQuery, VendorSearchQueryQueryVariables>(VendorSearchQueryDocument, options);
      }
export function useVendorSearchQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VendorSearchQueryQuery, VendorSearchQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VendorSearchQueryQuery, VendorSearchQueryQueryVariables>(VendorSearchQueryDocument, options);
        }
export type VendorSearchQueryQueryHookResult = ReturnType<typeof useVendorSearchQueryQuery>;
export type VendorSearchQueryLazyQueryHookResult = ReturnType<typeof useVendorSearchQueryLazyQuery>;
export type VendorSearchQueryQueryResult = Apollo.QueryResult<VendorSearchQueryQuery, VendorSearchQueryQueryVariables>;
export const VerifyEmailOtpDocument = gql`
    mutation VerifyEmailOtp($email: String!, $otp: String!) {
  verifyEmailOtp(email: $email, otp: $otp)
}
    `;
export type VerifyEmailOtpMutationFn = Apollo.MutationFunction<VerifyEmailOtpMutation, VerifyEmailOtpMutationVariables>;

/**
 * __useVerifyEmailOtpMutation__
 *
 * To run a mutation, you first call `useVerifyEmailOtpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyEmailOtpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyEmailOtpMutation, { data, loading, error }] = useVerifyEmailOtpMutation({
 *   variables: {
 *      email: // value for 'email'
 *      otp: // value for 'otp'
 *   },
 * });
 */
export function useVerifyEmailOtpMutation(baseOptions?: Apollo.MutationHookOptions<VerifyEmailOtpMutation, VerifyEmailOtpMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VerifyEmailOtpMutation, VerifyEmailOtpMutationVariables>(VerifyEmailOtpDocument, options);
      }
export type VerifyEmailOtpMutationHookResult = ReturnType<typeof useVerifyEmailOtpMutation>;
export type VerifyEmailOtpMutationResult = Apollo.MutationResult<VerifyEmailOtpMutation>;
export type VerifyEmailOtpMutationOptions = Apollo.BaseMutationOptions<VerifyEmailOtpMutation, VerifyEmailOtpMutationVariables>;
export const WatchOrdersDocument = gql`
    subscription watchOrders($patronId: Int!) {
  ordersChangedPatron(patronId: $patronId) {
    order {
      id
      firstName
      lastName
      isClaimed
      customerEmail
      paymentMethod
      businessId
      patron {
        id
      }
      referenceId
      customerPhoneNumber
      status
      details
      createdAt
      updatedAt
      amount
      orderStatus
      serviceFee
      subTotal
      vat
      convenienceFee
      netAmountToVendorsBank
      totalAppFees
    }
    mutation
  }
}
    `;

/**
 * __useWatchOrdersSubscription__
 *
 * To run a query within a React component, call `useWatchOrdersSubscription` and pass it any options that fit your needs.
 * When your component renders, `useWatchOrdersSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWatchOrdersSubscription({
 *   variables: {
 *      patronId: // value for 'patronId'
 *   },
 * });
 */
export function useWatchOrdersSubscription(baseOptions: Apollo.SubscriptionHookOptions<WatchOrdersSubscription, WatchOrdersSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<WatchOrdersSubscription, WatchOrdersSubscriptionVariables>(WatchOrdersDocument, options);
      }
export type WatchOrdersSubscriptionHookResult = ReturnType<typeof useWatchOrdersSubscription>;
export type WatchOrdersSubscriptionResult = Apollo.SubscriptionResult<WatchOrdersSubscription>;
export type MutateMeMutationVariables = Exact<{
  data: UserData;
  file?: InputMaybe<Scalars['Upload']>;
}>;


export type MutateMeMutation = { __typename?: 'Mutation', me?: { __typename?: 'MutationUser', update?: { __typename?: 'User', avatar?: string | null, email?: string | null, signedS3Avatar?: string | null } | null } | null };

export type BusinessTypeQueryQueryVariables = Exact<{ [key: string]: never; }>;


export type BusinessTypeQueryQuery = { __typename?: 'Query', patron?: { __typename?: 'QueryPatron', businessTypes?: Array<{ __typename?: 'BusinessType', value?: string | null, id?: number | null, isDisabled?: number | null } | null> | null } | null };

export type CreateConversationAboutOrderMutationVariables = Exact<{
  orderId: Scalars['String'];
}>;


export type CreateConversationAboutOrderMutation = { __typename?: 'Mutation', createConversationAboutOrder?: { __typename?: 'Conversation', id: string, title?: string | null, members?: Array<{ __typename?: 'User', id: string, firstName?: string | null, lastName?: string | null, signedS3Avatar?: string | null } | null> | null, messages?: Array<{ __typename?: 'Message', id: string, senderId?: string | null, content?: string | null, createdAt?: string | null } | null> | null } | null };

export type GetAllConversationsQueryVariables = Exact<{
  messageLimit?: InputMaybe<Scalars['Int']>;
}>;


export type GetAllConversationsQuery = { __typename?: 'Query', getAllConversations?: Array<{ __typename?: 'Conversation', id: string, title?: string | null, createdAt?: string | null, updatedAt?: string | null, orderId?: string | null, businessLogo?: string | null, businessName?: string | null, members?: Array<{ __typename?: 'User', id: string, firstName?: string | null, lastName?: string | null, signedS3Avatar?: string | null } | null> | null, memberDetails?: Array<{ __typename?: 'ConversationMember', userId: string, lastReadTimestamp?: string | null } | null> | null, messages?: Array<{ __typename?: 'Message', id: string, content?: string | null, createdAt?: string | null, sender?: { __typename?: 'User', id: string, firstName?: string | null, lastName?: string | null } | null } | null> | null } | null> | null };

export type GetConversationByIdQueryVariables = Exact<{
  conversationId: Scalars['String'];
}>;


export type GetConversationByIdQuery = { __typename?: 'Query', getConversationById?: { __typename?: 'Conversation', id: string, title?: string | null, createdAt?: string | null, updatedAt?: string | null, orderId?: string | null, members?: Array<{ __typename?: 'User', id: string, firstName?: string | null, lastName?: string | null, signedS3Avatar?: string | null } | null> | null, memberDetails?: Array<{ __typename?: 'ConversationMember', userId: string, lastReadTimestamp?: string | null } | null> | null, messages?: Array<{ __typename?: 'Message', id: string, content?: string | null, createdAt?: string | null, sender?: { __typename?: 'User', id: string, firstName?: string | null, lastName?: string | null } | null } | null> | null } | null };

export type SearchLocationQueryVariables = Exact<{
  productsSearchBusinessType?: InputMaybe<Scalars['String']>;
  productsSearchSearchString?: InputMaybe<Scalars['String']>;
  latitude?: InputMaybe<Scalars['Float']>;
  longitude?: InputMaybe<Scalars['Float']>;
  page?: InputMaybe<Scalars['Int']>;
  rows?: InputMaybe<Scalars['Int']>;
  maxDistanceInputKm?: InputMaybe<Scalars['Int']>;
}>;


export type SearchLocationQuery = { __typename?: 'Query', patron?: { __typename?: 'QueryPatron', searchLocation?: { __typename?: 'PatronMerchantProductsSearch', total?: number | null, page?: number | null, perPage?: number | null, data?: Array<{ __typename?: 'Business', businessId: string, businessName?: string | null, storeDescription?: string | null, city?: string | null, companyType?: string | null, country?: string | null, createdAt?: any | null, formattedAddress?: string | null, isOpen?: boolean | null, isVerified?: boolean | null, latitude?: number | null, longitude?: number | null, merchantType?: string | null, qrCode?: string | null, redirectUrl?: string | null, street?: string | null, signedS3UrlLogo?: string | null, signedS3UrlBanner?: string | null, updatedAt?: any | null, zipcode?: string | null, isAcceptingOrders?: boolean | null, isManualOpenCloseUpdate?: boolean | null, products?: Array<{ __typename?: 'Product', description?: string | null, price?: string | null, name?: string | null, qrCode?: string | null, id: string, createdAt?: any | null, updatedAt?: any | null, redirectUrl?: string | null, disabledAt?: any | null, images?: Array<{ __typename?: 'ProductImage', filepath?: string | null, signedS3Url?: string | null } | null> | null } | null> | null, users?: Array<{ __typename?: 'User', id: string, lastName?: string | null, role?: string | null, signedS3Avatar?: string | null, email?: string | null, firstName?: string | null, contactNumber?: string | null } | null> | null, businessSchedules?: Array<{ __typename?: 'BusinessSchedules', businessScheduleId: string, day?: string | null, openingTime?: any | null, closingTime?: any | null } | null> | null } | null> | null } | null } | null };

export type LoginMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
  isKeepLoggedIn?: InputMaybe<Scalars['Boolean']>;
  isPatron?: InputMaybe<Scalars['Boolean']>;
  isVerify?: InputMaybe<Scalars['Boolean']>;
}>;


export type LoginMutation = { __typename?: 'Mutation', login: { __typename?: 'LoginResponse', tokens?: { __typename?: 'Token', accessToken?: string | null, refreshToken?: string | null } | null } };

export type MarkConversationReadMutationVariables = Exact<{
  conversationId: Scalars['String'];
}>;


export type MarkConversationReadMutation = { __typename?: 'Mutation', markConversationRead?: boolean | null };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename?: 'Query', me?: { __typename?: 'User', id: string, firstName?: string | null, lastName?: string | null, email?: string | null, role?: string | null, contactNumber?: string | null, avatar?: string | null, signedS3Avatar?: string | null } | null };

export type MerchantProductsQueryVariables = Exact<{
  businessId: Scalars['Int'];
  page?: InputMaybe<Scalars['Int']>;
  rows?: InputMaybe<Scalars['Int']>;
  searchString?: InputMaybe<Scalars['String']>;
}>;


export type MerchantProductsQuery = { __typename?: 'Query', patron?: { __typename?: 'QueryPatron', merchantProducts?: { __typename?: 'PatronMerchantProducts', page?: number | null, perPage?: number | null, total?: number | null, products?: Array<{ __typename?: 'Product', description?: string | null, id: string, name?: string | null, price?: string | null, qrCode?: string | null, redirectUrl?: string | null, createdAt?: any | null, disabledAt?: any | null, updatedAt?: any | null, stock?: number | null, parLevel?: number | null, startDateTime?: any | null, endDateTime?: any | null, category?: string | null, images?: Array<{ __typename?: 'ProductImage', id: string, signedS3Url?: string | null, filepath?: string | null, createdAt?: any | null, updatedAt?: any | null, productId?: number | null } | null> | null, business?: { __typename?: 'Business', businessId: string, businessName?: string | null, storeDescription?: string | null, companyType?: string | null, isAcceptingOrders?: boolean | null, formattedAddress?: string | null, signedS3UrlLogo?: string | null, signedS3UrlBanner?: string | null, isManualOpenCloseUpdate?: boolean | null, products?: Array<{ __typename?: 'Product', id: string } | null> | null, businessSchedules?: Array<{ __typename?: 'BusinessSchedules', businessScheduleId: string, day?: string | null, openingTime?: any | null, closingTime?: any | null } | null> | null } | null, variations?: Array<{ __typename?: 'ProductVariation', heading?: string | null, id: string, maxChoices?: number | null, minChoices?: number | null, required?: boolean | null, options?: Array<{ __typename?: 'ProductVariationOption', choiceName?: string | null, extraCost?: number | null, id: string } | null> | null } | null> | null } | null> | null } | null } | null };

export type UserNotificationsQueryVariables = Exact<{
  perPage?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
}>;


export type UserNotificationsQuery = { __typename?: 'Query', notifications?: { __typename?: 'QueryNotifications', userNotifications?: { __typename?: 'QueryNotification', itemCount?: number | null, page?: number | null, pageCount?: number | null, perPage?: number | null, unreadCount?: number | null, data?: Array<{ __typename?: 'NotificationData', body?: string | null, createdAt?: any | null, id?: number | null, isRead?: string | null, sentAt?: any | null, title?: string | null, type?: string | null, uid?: number | null } | null> | null } | null } | null };

export type OrderTotalQueryVariables = Exact<{
  data: OrderData;
}>;


export type OrderTotalQuery = { __typename?: 'Query', patron?: { __typename?: 'QueryPatron', orderTotal?: { __typename?: 'PatronOrderTotal', computedFee?: number | null, convenienceFee?: number | null, serviceFee?: number | null, total?: number | null, type: MerchantEnum, vat?: number | null, orderSummary?: { __typename?: 'OrderSummary', subTotal?: number | null, orderedItems?: Array<{ __typename?: 'OrderedItems', extraCost?: number | null, orderedQty?: number | null, product?: { __typename?: 'Product', id: string } | null } | null> | null } | null } | null } | null };

export type ProductPurchaseMutationVariables = Exact<{
  data: PurchaseData;
}>;


export type ProductPurchaseMutation = { __typename?: 'Mutation', patron?: { __typename?: 'MutationPatron', purchase: { __typename?: 'FiservResponseType', url?: string | null } } | null };

export type ProductsSearchQueryVariables = Exact<{
  productsSearchBusinessType: Scalars['String'];
  productsSearchSearchString?: InputMaybe<Scalars['String']>;
  productsSearchLatitude?: InputMaybe<Scalars['Float']>;
  productsSearchLongitude?: InputMaybe<Scalars['Float']>;
}>;


export type ProductsSearchQuery = { __typename?: 'Query', patron?: { __typename?: 'QueryPatron', productsSearch?: { __typename?: 'PatronMerchantProductsSearch', data?: Array<{ __typename?: 'Business', businessId: string, businessName?: string | null, storeDescription?: string | null, city?: string | null, companyType?: string | null, country?: string | null, createdAt?: any | null, formattedAddress?: string | null, isOpen?: boolean | null, isVerified?: boolean | null, latitude?: number | null, longitude?: number | null, merchantType?: string | null, qrCode?: string | null, redirectUrl?: string | null, street?: string | null, signedS3UrlLogo?: string | null, signedS3UrlBanner?: string | null, updatedAt?: any | null, zipcode?: string | null, isAcceptingOrders?: boolean | null, isManualOpenCloseUpdate?: boolean | null, products?: Array<{ __typename?: 'Product', description?: string | null, price?: string | null, name?: string | null, qrCode?: string | null, id: string, createdAt?: any | null, updatedAt?: any | null, redirectUrl?: string | null, disabledAt?: any | null, images?: Array<{ __typename?: 'ProductImage', filepath?: string | null, signedS3Url?: string | null } | null> | null } | null> | null, users?: Array<{ __typename?: 'User', id: string, lastName?: string | null, role?: string | null, signedS3Avatar?: string | null, email?: string | null, firstName?: string | null, contactNumber?: string | null } | null> | null, businessSchedules?: Array<{ __typename?: 'BusinessSchedules', businessScheduleId: string, day?: string | null, openingTime?: any | null, closingTime?: any | null } | null> | null } | null> | null } | null } | null };

export type PublicMerchantQueryVariables = Exact<{
  businessId: Scalars['ID'];
}>;


export type PublicMerchantQuery = { __typename?: 'Query', publicMerchant?: { __typename?: 'User', avatar?: string | null, contactNumber?: string | null, email?: string | null, firstName?: string | null, id: string, lastName?: string | null, role?: string | null, business?: { __typename?: 'Business', businessName?: string | null, storeDescription?: string | null, isAcceptingOrders?: boolean | null, isOpen?: boolean | null, businessId: string, latitude?: number | null, longitude?: number | null, merchantType?: string | null, signedS3UrlLogo?: string | null, signedS3UrlBanner?: string | null, formattedAddress?: string | null, openingTime?: any | null, closingTime?: any | null, isManualOpenCloseUpdate?: boolean | null, products?: Array<{ __typename?: 'Product', id: string } | null> | null, businessSchedules?: Array<{ __typename?: 'BusinessSchedules', businessScheduleId: string, day?: string | null, openingTime?: any | null, closingTime?: any | null } | null> | null } | null } | null };

export type PublicMerchantProductsQueryVariables = Exact<{
  businessId: Scalars['Int'];
  page?: InputMaybe<Scalars['Int']>;
  rows?: InputMaybe<Scalars['Int']>;
  searchString?: InputMaybe<Scalars['String']>;
}>;


export type PublicMerchantProductsQuery = { __typename?: 'Query', publicMerchantProducts?: { __typename?: 'PatronMerchantProducts', page?: number | null, perPage?: number | null, total?: number | null, products?: Array<{ __typename?: 'Product', description?: string | null, id: string, name?: string | null, price?: string | null, qrCode?: string | null, redirectUrl?: string | null, createdAt?: any | null, disabledAt?: any | null, updatedAt?: any | null, stock?: number | null, parLevel?: number | null, startDateTime?: any | null, endDateTime?: any | null, images?: Array<{ __typename?: 'ProductImage', id: string, signedS3Url?: string | null, filepath?: string | null, createdAt?: any | null, updatedAt?: any | null, productId?: number | null } | null> | null, business?: { __typename?: 'Business', businessId: string, businessName?: string | null, storeDescription?: string | null, companyType?: string | null, isAcceptingOrders?: boolean | null, formattedAddress?: string | null, signedS3UrlLogo?: string | null, signedS3UrlBanner?: string | null, isManualOpenCloseUpdate?: boolean | null, products?: Array<{ __typename?: 'Product', id: string } | null> | null, businessSchedules?: Array<{ __typename?: 'BusinessSchedules', businessScheduleId: string, day?: string | null, openingTime?: any | null, closingTime?: any | null } | null> | null } | null, variations?: Array<{ __typename?: 'ProductVariation', heading?: string | null, id: string, maxChoices?: number | null, minChoices?: number | null, required?: boolean | null, options?: Array<{ __typename?: 'ProductVariationOption', choiceName?: string | null, extraCost?: number | null, id: string } | null> | null } | null> | null } | null> | null } | null };

export type PurchaseHistoryQueryVariables = Exact<{
  status: Scalars['String'];
  rows?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
}>;


export type PurchaseHistoryQuery = { __typename?: 'Query', patron?: { __typename?: 'QueryPatron', queryPurchases?: { __typename?: 'PurchaseHistoryResponseType', page?: number | null, total?: number | null, pages?: number | null, data?: Array<{ __typename?: 'Order', amount?: number | null, businessId: string, convenienceFee?: number | null, createdAt?: any | null, customerEmail?: string | null, customerPhoneNumber?: string | null, firstName?: string | null, id: string, isClaimed?: boolean | null, lastName?: string | null, specialInstructions?: string | null, cashPaymentConfirmed?: boolean | null, isSeen?: boolean | null, orderStatus?: OrderStatusEnum | null, status?: string | null, paymentMethod?: string | null, referenceId?: string | null, subTotal?: number | null, serviceFee?: number | null, updatedAt?: any | null, details?: string | null, orderItems?: Array<{ __typename?: 'OrderItem', description?: string | null, id: string, name?: string | null, price?: number | null, productId: string, quantity?: number | null, variations?: Array<{ __typename?: 'ProductVariation', id: string, variation_id?: string | null, selected_options?: Array<number | null> | null } | null> | null } | null> | null, business?: { __typename?: 'Business', businessName?: string | null, businessId: string, formattedAddress?: string | null, latitude?: number | null, longitude?: number | null, signedS3UrlLogo?: string | null, products?: Array<{ __typename?: 'Product', id: string } | null> | null } | null } | null> | null } | null } | null };

export type PurchaseHistorySingleQueryVariables = Exact<{
  orderId: Scalars['ID'];
}>;


export type PurchaseHistorySingleQuery = { __typename?: 'Query', patron?: { __typename?: 'QueryPatron', queryPurchase?: { __typename?: 'Order', amount?: number | null, businessId: string, createdAt?: any | null, id: string, isClaimed?: boolean | null, orderStatus?: OrderStatusEnum | null, paymentMethod?: string | null, referenceId?: string | null, status?: string | null, customerEmail?: string | null, customerPhoneNumber?: string | null, specialInstructions?: string | null, cashPaymentConfirmed?: boolean | null, isSeen?: boolean | null, business?: { __typename?: 'Business', businessName?: string | null, businessId: string, products?: Array<{ __typename?: 'Product', id: string } | null> | null } | null, orderItems?: Array<{ __typename?: 'OrderItem', id: string, description?: string | null, price?: number | null, name?: string | null, productId: string, quantity?: number | null, images?: Array<{ __typename?: 'OrderItemImage', id: string, filepath?: string | null } | null> | null, variations?: Array<{ __typename?: 'ProductVariation', id: string, variation_id?: string | null, selected_options?: Array<number | null> | null } | null> | null } | null> | null } | null } | null };

export type MaintenanceModeQueryVariables = Exact<{ [key: string]: never; }>;


export type MaintenanceModeQuery = { __typename?: 'Query', isMaintenanceMode?: boolean | null };

export type PatronSingleOrderQueryVariables = Exact<{
  orderReferenceId: Scalars['String'];
}>;


export type PatronSingleOrderQuery = { __typename?: 'Query', patron?: { __typename?: 'QueryPatron', order?: { __typename?: 'Order', amount?: number | null, id: string, subTotal?: number | null, serviceFee?: number | null, vat?: number | null, referenceId?: string | null, createdAt?: any | null, updatedAt?: any | null, specialInstructions?: string | null, cashPaymentConfirmed?: boolean | null, isSeen?: boolean | null, patron?: { __typename?: 'User', firstName?: string | null, lastName?: string | null } | null, orderItems?: Array<{ __typename?: 'OrderItem', price?: number | null, description?: string | null, id: string, quantity?: number | null, productId: string, name?: string | null, product?: { __typename?: 'Product', id: string, images?: Array<{ __typename?: 'ProductImage', id: string, signedS3Url?: string | null } | null> | null } | null, variations?: Array<{ __typename?: 'ProductVariation', id: string, required?: boolean | null, maxChoices?: number | null, minChoices?: number | null, heading?: string | null, options?: Array<{ __typename?: 'ProductVariationOption', choiceName?: string | null, extraCost?: number | null, id: string } | null> | null } | null> | null } | null> | null } | null } | null };

export type ReadAllNotificationsMutationVariables = Exact<{
  perPage?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
}>;


export type ReadAllNotificationsMutation = { __typename?: 'Mutation', notifications?: { __typename?: 'MutationNotification', readAllNotifications?: { __typename?: 'QueryNotifications', userNotifications?: { __typename?: 'QueryNotification', itemCount?: number | null } | null } | null } | null };

export type ReadNotificationMutationVariables = Exact<{
  notificationId?: InputMaybe<Scalars['Int']>;
}>;


export type ReadNotificationMutation = { __typename?: 'Mutation', notifications?: { __typename?: 'MutationNotification', readNotification?: { __typename?: 'NotificationData', isRead?: string | null, id?: number | null } | null } | null };

export type RefreshTokenMutationVariables = Exact<{
  refreshToken?: InputMaybe<Scalars['String']>;
}>;


export type RefreshTokenMutation = { __typename?: 'Mutation', refreshToken?: { __typename?: 'UserAuthenticationTokens', accessToken?: string | null, refreshToken?: string | null } | null };

export type RegisterMutationVariables = Exact<{
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  contactNumber: Scalars['String'];
  userType: Scalars['String'];
  firebaseUid: Scalars['String'];
}>;


export type RegisterMutation = { __typename?: 'Mutation', register: { __typename?: 'User', id: string, firstName?: string | null, lastName?: string | null, email?: string | null, role?: string | null, contactNumber?: string | null, avatar?: string | null } };

export type RequestEmailOtpMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type RequestEmailOtpMutation = { __typename?: 'Mutation', generateAndSendEmailOtp: boolean };

export type RequestRefundMutationVariables = Exact<{
  productIds?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  orderId: Scalars['ID'];
  reason?: InputMaybe<Scalars['String']>;
}>;


export type RequestRefundMutation = { __typename?: 'Mutation', patron?: { __typename?: 'MutationPatron', requestRefund?: { __typename?: 'Order', status?: string | null } | null } | null };

export type ResolveBuskingPayMutationVariables = Exact<{
  data: PurchaseData;
}>;


export type ResolveBuskingPayMutation = { __typename?: 'Mutation', patron?: { __typename?: 'MutationPatron', resolveCashPurchase?: { __typename?: 'Order', referenceId?: string | null, amount?: number | null, businessId: string } | null } | null };

export type ResolveCashPurchaseMutationVariables = Exact<{
  data: PurchaseData;
}>;


export type ResolveCashPurchaseMutation = { __typename?: 'Mutation', patron?: { __typename?: 'MutationPatron', resolveCashPurchase?: { __typename?: 'Order', amount?: number | null, createdAt?: any | null, customerEmail?: string | null, customerPhoneNumber?: string | null, firstName?: string | null, id: string, isClaimed?: boolean | null, lastName?: string | null, paymentMethod?: string | null, updatedAt?: any | null, vat?: number | null, subTotal?: number | null, serviceFee?: number | null, status?: string | null, referenceId?: string | null, specialInstructions?: string | null, cashPaymentConfirmed?: boolean | null, isSeen?: boolean | null, business?: { __typename?: 'Business', businessId: string, businessName?: string | null, companyType?: string | null, createdAt?: any | null, formattedAddress?: string | null, isOpen?: boolean | null, isVerified?: boolean | null, latitude?: number | null, longitude?: number | null, merchantType?: string | null, updatedAt?: any | null, products?: Array<{ __typename?: 'Product', id: string } | null> | null } | null, orderItems?: Array<{ __typename?: 'OrderItem', description?: string | null, id: string, name?: string | null, price?: number | null, productId: string, quantity?: number | null, product?: { __typename?: 'Product', name?: string | null, price?: string | null, description?: string | null, id: string, updatedAt?: any | null, createdAt?: any | null, disabledAt?: any | null } | null, images?: Array<{ __typename?: 'OrderItemImage', filepath?: string | null, id: string } | null> | null } | null> | null } | null } | null };

export type RetrieveStripeCheckoutSessionMutationVariables = Exact<{
  sessionId: Scalars['String'];
}>;


export type RetrieveStripeCheckoutSessionMutation = { __typename?: 'Mutation', retrieveStripeCheckoutSession: { __typename?: 'StripeCheckoutSessionType', status: string, orderId?: string | null } };

export type SendMessageMutationVariables = Exact<{
  conversationId: Scalars['String'];
  content: Scalars['String'];
}>;


export type SendMessageMutation = { __typename?: 'Mutation', sendMessage?: { __typename?: 'Message', id: string, content?: string | null, createdAt?: string | null, senderId?: string | null } | null };

export type WatchNewConversationMessageSubscriptionVariables = Exact<{
  userId: Scalars['Int'];
}>;


export type WatchNewConversationMessageSubscription = { __typename?: 'Subscription', newConversationMessage?: { __typename?: 'ConversationSubscriptionType', newMessageDetails?: { __typename?: 'NewMessageDetails', members?: Array<string | null> | null, conversationId?: string | null, message?: { __typename?: 'Message', id: string, content?: string | null, senderId?: string | null, conversationId?: string | null, createdAt?: string | null, sender?: { __typename?: 'User', id: string, firstName?: string | null, lastName?: string | null } | null } | null } | null } | null };

export type VendorSearchQueryQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>;
  rows?: InputMaybe<Scalars['Int']>;
  businessType?: InputMaybe<Scalars['String']>;
}>;


export type VendorSearchQueryQuery = { __typename?: 'Query', patron?: { __typename?: 'QueryPatron', merchants?: { __typename?: 'PatronMerchants', total?: number | null, page?: number | null, perPage?: number | null, merchants?: Array<{ __typename?: 'Business', formattedAddress?: string | null, merchantType?: string | null, longitude?: number | null, latitude?: number | null, businessId: string, products?: Array<{ __typename?: 'Product', id: string, name?: string | null, price?: string | null, images?: Array<{ __typename?: 'ProductImage', filepath?: string | null } | null> | null } | null> | null } | null> | null } | null } | null };

export type VerifyEmailOtpMutationVariables = Exact<{
  email: Scalars['String'];
  otp: Scalars['String'];
}>;


export type VerifyEmailOtpMutation = { __typename?: 'Mutation', verifyEmailOtp: boolean };

export type WatchOrdersSubscriptionVariables = Exact<{
  patronId: Scalars['Int'];
}>;


export type WatchOrdersSubscription = { __typename?: 'Subscription', ordersChangedPatron?: { __typename?: 'OrderSubscriptionType', mutation?: MutationType | null, order?: { __typename?: 'Order', id: string, firstName?: string | null, lastName?: string | null, isClaimed?: boolean | null, customerEmail?: string | null, paymentMethod?: string | null, businessId: string, referenceId?: string | null, customerPhoneNumber?: string | null, status?: string | null, details?: string | null, createdAt?: any | null, updatedAt?: any | null, amount?: number | null, orderStatus?: OrderStatusEnum | null, serviceFee?: number | null, subTotal?: number | null, vat?: number | null, convenienceFee?: number | null, netAmountToVendorsBank?: number | null, totalAppFees?: number | null, patron?: { __typename?: 'User', id: string } | null } | null } | null };
