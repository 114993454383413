import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

// Define the initial state
const initialState = {
  meData: null,
  purchaseHistoryList: [],
  allProducts: {},
  isLoading: false,
  error: null,
};

// Create the Zustand store using the new syntax
export const allOrdersSubscriptionStore = create(
  devtools((set) => ({
    ...initialState,
    
    setMeData: (data: any) => set({ meData: data }),
    
    setPurchaseHistoryList: (data: any) => set({ purchaseHistoryList: data }),
    
    setAllProducts: (businessId: any, products: any) =>
      set((state: { allProducts: any; }) => ({
        allProducts: {
          ...state.allProducts,
          [businessId]: products,
        },
      })),
    
    setIsLoading: (loading: any) => set({ isLoading: loading }),
    
    setError: (error: any) => set({ error }),
    }))
);
