// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBC8PVwfcjNolCQuR_7KxrfM_46mIL3p6Y",
  authDomain: "auth.ywait.com.au",
  projectId: "bubbly-jigsaw-391901",
  storageBucket: "bubbly-jigsaw-391901.appspot.com",
  messagingSenderId: "445643412723",
  appId: "1:445643412723:web:df82754ddbaa3ca5265386",
  measurementId: "G-C73ENFR0GE"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export { app, auth };