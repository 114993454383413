// conversationsStore.ts - With improved merging support
import { create } from "zustand";

interface ConversationState {
  conversations: any[];
  detailedConversation: any | null;
  loading: boolean;
  unreadConversationsCount: number;
  fetchDetailedConversationCBF: ((conversationId: string) => Promise<any>) | null;
  fetchConversations: (() => Promise<any>) | null;
  
  // Actions
  setConversations: (conversations: any[]) => void;
  setDetailedConversation: (conversation: any) => void;
  setLoading: (loading: boolean) => void;
  setUnreadConversationsCount: (count: number) => void;
  setFetchDetailedConversationCBF: (callback: ((conversationId: string) => Promise<any>)) => void;
  setFetchAllConversationsCBF: (callback: (() => Promise<any>)) => void;
  calculateUnreadCount: (conversations: any[], currentUserId: string) => number;
  updateConversation: (conversationId: string | number, updateFn: (conversation: any) => any) => void;
}

export const useConversationStore = create<ConversationState>((set, get) => ({
  conversations: [],
  detailedConversation: null,
  loading: false,
  unreadConversationsCount: 0,
  fetchDetailedConversationCBF: null,
  fetchConversations: null,
  
  setConversations: (conversations) => {
    set({ conversations });
  },
  
  setDetailedConversation: (conversation) => {
    set({ detailedConversation: conversation });
    
    // Update the conversation in the conversations list if it exists
    const { conversations } = get();
    const updatedConversations = conversations.map((conv) => 
      conv.id === conversation.id ? { ...conv, ...conversation } : conv
    );
    
    set({ conversations: updatedConversations });
  },
  
  // New method to update a specific conversation using a function
  updateConversation: (conversationId, updateFn) => {
    const { conversations } = get();
    const updatedConversations = conversations.map((conv) => {
      if (conv.id == conversationId) { // Use loose equality to handle number/string conversion
        return updateFn(conv);
      }
      return conv;
    });
    
    set({ conversations: updatedConversations });
  },
  
  setLoading: (loading) => set({ loading }),
  
  setUnreadConversationsCount: (unreadConversationsCount) => 
    set({ unreadConversationsCount }),
  
  setFetchDetailedConversationCBF: (callback) => 
    set({ fetchDetailedConversationCBF: callback }),
  
  setFetchAllConversationsCBF: (callback) => 
    set({ fetchConversations: callback }),
  
  // Method to calculate unread count based on the conversations
  calculateUnreadCount: (conversations, currentUserId) => {
    if (!currentUserId || !conversations.length) return 0;
    
    // Count conversations with unread messages
    const unreadCount = conversations.filter((conversation) => {
      if (!conversation?.messages || !conversation.messages.length) return false;
      
      // Find the user's member details
      const userMemberDetails = conversation.memberDetails?.find(
        (member: any) => member.userId === currentUserId
      );
      
      if (!userMemberDetails || !userMemberDetails.lastReadTimestamp) return true;
      
      // Get the user's last read timestamp
      const lastReadTime = typeof userMemberDetails.lastReadTimestamp === "string" && 
        /^\d+$/.test(userMemberDetails.lastReadTimestamp)
        ? new Date(parseInt(userMemberDetails.lastReadTimestamp, 10))
        : new Date(userMemberDetails.lastReadTimestamp);
      
      // Get the latest message timestamp
      const latestMessage = [...conversation.messages].sort((a: any, b: any) => {
        return parseInt(b.createdAt, 10) - parseInt(a.createdAt, 10);
      })[0];
      
      if (!latestMessage) return false;
      
      // Check if the latest message is from another user and is newer than the last read time
      const latestMessageTime = typeof latestMessage.createdAt === "string" && 
        /^\d+$/.test(latestMessage.createdAt)
        ? new Date(parseInt(latestMessage.createdAt, 10))
        : new Date(latestMessage.createdAt);
      
      const isFromCurrentUser = latestMessage.sender?.id === currentUserId || 
                               latestMessage.senderId === currentUserId;
      
      return !isFromCurrentUser && latestMessageTime > lastReadTime;
    }).length;
    
    return unreadCount;
  }
}));