import React, { useEffect, useState } from "react";
import {
  getAuth,
  signInAnonymously,
  onAuthStateChanged,
  signOut,
  User,
} from "firebase/auth";
import { FaSpinner } from "react-icons/fa";
import { useMeLazyQuery, useRegisterMutation } from "src/generated/graphql";
import { useRouter } from "next/router";

interface AuthProviderProps {
  children: React.ReactNode;
}

const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [isFullyAuthenticated, setIsFullyAuthenticated] = useState(false);
  const [authError, setAuthError] = useState<Error | null>(null);

  const router = useRouter();

  const [registerMutation] = useRegisterMutation({
    onCompleted: () => {
      console.log("User registered successfully as ANONYMOUS in DB");
      setIsFullyAuthenticated(true);
    },
    onError: (error) => {
      console.error(
        "Error registering user as anonymous in DB:",
        error.message
      );
      setAuthError(error);
    },
  });

  const [tryFetchUser] = useMeLazyQuery({
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (data?.me) {
        setIsFullyAuthenticated(true);
      }
    },
  });

  useEffect(() => {
    const auth = getAuth();
    let unsubscribe: (() => void) | undefined;

    const initializeAuth = async (user: User | null) => {
      if (user) {
        const { data: freshMeData } = await tryFetchUser();
        if (freshMeData?.me) {
          console.log("User exists in DB");
          setIsFullyAuthenticated(true);
          if (freshMeData.me.role === "anon") {
            return;
          }
          if (
            freshMeData.me.email?.endsWith("phone.user") ||
            !freshMeData.me.email ||
            !freshMeData.me.firstName ||
            !freshMeData.me.lastName
          ) {
            router.push("/login");
          }
          return;
        }

        // Register new anonymous user
        if (user.isAnonymous) {
          await registerMutation({
            variables: {
              firstName: "",
              lastName: "",
              email: `${user.uid}@anonymous.user`,
              userType: "anon",
              contactNumber: user.uid,
              firebaseUid: user.uid,
            },
          });
        }
      } else {
        try {
          await signInAnonymously(auth);
        } catch (error) {
          console.error("Error signing in anonymously:", error);
          setAuthError(
            error instanceof Error ? error : new Error("Authentication failed")
          );
          await signOut(auth);
        }
      }
    };

    unsubscribe = onAuthStateChanged(auth, initializeAuth, (error) => {
      console.error("Auth state change error:", error);
      setAuthError(
        error instanceof Error
          ? error
          : new Error("Authentication state change failed")
      );
    });

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [registerMutation, tryFetchUser]);

  if (authError) {
    return (
      <div className="flex flex-col items-center justify-center h-full min-h-full min-w-full bg-white text-center p-4">
        <p className="text-red-500 mb-4">Authentication Error</p>
        <p className="text-sm text-gray-600 mb-4">{authError.message}</p>
        <p className="text-sm text-gray-600">
          We apologise for the inconvenience. Please reload the page.
        </p>
      </div>
    );
  }

  if (!isFullyAuthenticated) {
    return (
      <div className="flex items-center justify-center h-screen min-h-full min-w-full bg-white">
        <FaSpinner className="animate-spin text-5b5-softer-blue text-4xl" />
      </div>
    );
  }

  return <>{children}</>;
};

export default AuthProvider;
