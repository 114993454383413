import { InMemoryCache } from "@apollo/client";

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        publicMerchant: {
          merge: true,
        },
        merchantProducts: {
          merge: true,
        }
      }
    },
    Business: {
      keyFields: ["businessId"],
      fields: {
        products: {
          merge(_existing = [], incoming) {
            return incoming; // For products array, prefer new data
          }
        }
      }
    },
    ProductVariationOption: {
      keyFields: ["id"], // If you have IDs, better to use them than false
      // If no stable IDs exist, then keep keyFields: false
    },
    ProductVariation: {
      keyFields: ["id"], // If you have IDs, better to use them than false
      // If no stable IDs exist, then keep keyFields: false
    },
    MerchantProduct: {
      keyFields: ["id"],
      fields: {
        variations: {
          merge(_existing = [], incoming) {
            return incoming; // For variations array, prefer new data
          }
        }
      }
    }
  }
});

export default cache;