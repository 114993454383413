import { useMemo } from "react";
import { ApolloClient, from, split } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";
import { getMainDefinition } from "@apollo/client/utilities";

import merge from "deepmerge";
import isEqual from "lodash/isEqual";

import cache from "./cache";
import uploadLink from "./createUploadLink";
import { getAuth } from "firebase/auth";

export const APOLLO_STATE_PROP_NAME = "__APOLLO_STATE__";

/**Function that the takes and parses cookie from the link chain context.
 * Then sets the access token to the authorization header via operation setContext.
 * Returns an ApolloLink.
 */

// In your Apollo Client setup
const authLink = setContext(async (_, { headers }) => {
  const auth = getAuth();
  const user = auth.currentUser;
  let token = null;

  if (user) {
    token = await user.getIdToken(); // Fetch the current token
  }

  if (token) {
    // save to session storage with a 30 minutes expiry
    sessionStorage.setItem(
      "tokenFromFirebase",
      JSON.stringify({
        tokenFromFirebase: token,
        expiry: Date.now() + 1800000,
      })
    );
  } else {
    // set token to value retrieved from session storage
    const retrievedTokenObject = sessionStorage.getItem("tokenFromFirebase");
    if (retrievedTokenObject) {
      const parsedTokenObject = JSON.parse(retrievedTokenObject);
      if (parsedTokenObject.expiry > Date.now()) {
        token = parsedTokenObject.tokenFromFirebase;
      }
    }
  }

  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : "",
      AppOrAdmin: "app",
    },
  };
});

const wsUrl = `${process.env.API_URL}`;

console.log("wsUrl", wsUrl);

const wsLink = new GraphQLWsLink(
  createClient({
    url: wsUrl,
    connectionParams: async () => {
      const auth = getAuth();
      const user = auth.currentUser;
      let token = null;

      if (user) {
        token = await user.getIdToken();
      }

      if (token) {
        // save to session storage with a 30 minutes expiry
        sessionStorage.setItem(
          "tokenFromFirebase",
          JSON.stringify({
            tokenFromFirebase: token,
            expiry: Date.now() + 1800000,
          })
        );
      } else {
        // set token to value retrieved from session storage
        const retrievedTokenObject =
          sessionStorage.getItem("tokenFromFirebase");
        if (retrievedTokenObject) {
          const parsedTokenObject = JSON.parse(retrievedTokenObject);
          if (parsedTokenObject.expiry > Date.now()) {
            token = parsedTokenObject.tokenFromFirebase;
          }
        }
      }

      return {
        Authorization: token ? `Bearer ${token}` : "",
      };
    },
    on: {
      connected: () => {
        console.log(`WebSocket connected to ${wsUrl}`);
      },
      connecting: () => {
        console.log(`WebSocket connecting to ${wsUrl}`);
      },
      closed: (event) => {
        console.log(`WebSocket disconnected from ${wsUrl}`, event);
        // Add reconnect logic here
      },
      error: (err) => {
        console.error(`WebSocket connection error`, err);
      },
      message: (data) => {
        console.log(`WebSocket message received:`, data);
      },
    },
  })
);

const httpLink = from([authLink, uploadLink]);

const link = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  httpLink
);

let apolloClient: ApolloClient<any>;

// New one with subscriptions
function createApolloClient() {
  return new ApolloClient({
    ssrMode: typeof window === "undefined",
    link,
    cache,
    credentials: "include",
  });
}

export function initializeApollo(initialState = null) {
  if (!apolloClient) {
    apolloClient = createApolloClient();
  }

  if (initialState) {
    const existingCache = apolloClient.extract();
    const data = merge(existingCache, initialState, {
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter((d) =>
          sourceArray.every((s) => !isEqual(d, s))
        ),
      ],
    });
    apolloClient.cache.restore(data);
  }

  return apolloClient;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function addApolloState(client: any, pageProps: any) {
  if (pageProps?.props) {
    pageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract();
  }

  return pageProps;
}
//
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useApollo(pageProps: any) {
  const state = pageProps[APOLLO_STATE_PROP_NAME];
  const store = useMemo(() => initializeApollo(state), [state]);
  return store;
}
